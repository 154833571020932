import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import Link from '@mui/material/Link';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

//gambar
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import PrintIcon from '@mui/icons-material/Print';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CachedIcon from '@mui/icons-material/Cached';
import SaveIcon from '@mui/icons-material/Save';
import ListIcon from '@mui/icons-material/List';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgba(108,122,224,255)",
        borderColor: "rgba(108,122,224,255)",
        color: "white"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "rgba(248,246,255,255)",
        borderColor: "rgba(248,246,255,255)"
    },
}));

function Pembelian(props, ref) {
    //state
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [kode, setKode] = useState("");
    const [pencarian, setPencarian] = useState("");
    const [data_produk, setDataProduk] = useState([]);
    const [jumlah_baris_produk] = useState(10);
    const [jumlah_halaman_produk, setJumlahHalamanProduk] = useState(0);
    const [halaman_produk, setHalamanProduk] = useState(0);
    const [id_draft, setIdDraft] = useState("");
    const [id_draft_hapus, setIdDraftHapus] = useState("");
    const [data_draft, setDataDraft] = useState([]);
    const [jumlah_baris_draft] = useState(10);
    const [jumlah_halaman_draft, setJumlahHalamanDraft] = useState(0);
    const [halaman_draft, setHalamanDraft] = useState(0);
    const [data_belanja, setDataBelanja] = useState([]);
    const [data_metode_bayar, setDataMetodeBayar] = useState([]);
    const [jumlah_metode_bayar, setJumlahMetodeBayar] = useState(1);
    const [metode_bayar, setMetodeBayar] = useState("1");
    const [metode_bayar2, setMetodeBayar2] = useState("");
    const [jumlah_bayar, setJumlahBayar] = useState(0);
    const [jumlah_bayar2, setJumlahBayar2] = useState(0);
    const [tanggal_jatuh_tempo, setTanggalJatuhTempo] = useState(null);
    const [tanggal_pembelian, setTanggalPembelian] = useState(new Date());
    const [no_faktur, setNoFaktur] = useState("");
    const [data_supplier, setDataSupplier] = useState([]);
    const [supplier, setSupplier] = useState(null);
    const [input_supplier, setInputSupplier] = useState("");
    const [total, setTotal] = useState(0);
    const [diskon, setDiskon] = useState(0);
    const [pembulatan, setPembulatan] = useState(0);
    const [grand_total, setGrandTotal] = useState(0);
    const [kembalian, setKembalian] = useState(0);

    const [tampil_dialog_diskon, setTampilDialogDiskon] = useState(false);
    const [tampil_dialog_cari_produk, setTampilDialogCariProduk] = useState(false);
    const [tampil_dialog_draft, setTampilDialogDraft] = useState(false);
    const [tampil_dialog_hapus_draft, setTampilDialogHapusDraft] = useState(false);
    const [tampil_dialog_batal, setTampilDialogBatal] = useState(false);
    const [tampil_dialog_bayar, setTampilDialogBayar] = useState(false);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/kasir/tampil_metode_bayar.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataMetodeBayar(data.data);
                }
                else {
                    setDataMetodeBayar([]);
                }
            })
            .catch(error => {
                setDataMetodeBayar([]);
            }
            );

        fetch(props.aplikasi + '/supplier/tampil_supplier.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: "1"
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataSupplier(data.data);
                }
                else {
                    setDataSupplier([]);
                }
            })
            .catch(error => {
                setDataSupplier([]);
            }
            );
    }, [props.aplikasi]);

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(2)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const hitung_pembulatan = (vartotal, vardiskon) => {
        let tampung_grand_total = parseFloat(vartotal) - parseFloat(vardiskon);
        let tampung_pembulatan = Math.round(tampung_grand_total / 100) * 100 - tampung_grand_total;
        setPembulatan(tampung_pembulatan);
        setGrandTotal(vartotal - vardiskon + tampung_pembulatan);
    }

    const cari = () => {
        fetch(props.aplikasi + '/produk/tampil_cari_produk.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        kode: kode
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    let cari_id_jenis = data.data[0]["id_jenis"];
                    let cari_jenis = data.data[0]["jenis"];
                    let cari_id_produk = data.data[0]["id_produk"];
                    let cari_nama = data.data[0]["nama"];
                    let cari_harga_beli = data.data[0]["harga_beli"];
                    let cari_harga_beli2 = data.data[0]["harga_beli2"];
                    let cari_satuan = data.data[0]["satuan"];

                    let tampung = [...data_belanja];
                    let awal = 1;
                    let akhir = tampung.length;
                    let cari = "";
                    if (akhir > 0) {
                        while (awal <= akhir) {
                            if (cari_id_produk === tampung[awal - 1]["id_produk"]) {
                                tampung[awal - 1]["harga_beli"] = cari_harga_beli;
                                tampung[awal - 1]["harga_beli2"] = cari_harga_beli2;
                                tampung[awal - 1]["satuan"] = cari_satuan;
                                tampung[awal - 1]["jumlah"] = parseFloat(tampung[awal - 1]["jumlah"]) + 1;
                                tampung[awal - 1]["subtotal"] = parseFloat(tampung[awal - 1]["jumlah"]) * parseFloat(tampung[awal - 1]["harga_beli"]);

                                tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                                cari = "ada";
                                break;
                            }
                            awal++;
                        }

                        if (cari === "") {
                            tampung.push({ "id_jenis": cari_id_jenis, "jenis": cari_jenis, "id_produk": cari_id_produk, "nama": cari_nama, "harga_beli": cari_harga_beli, "harga_beli2": cari_harga_beli2, "satuan": cari_satuan, "jumlah": 1, "subtotal": cari_harga_beli, "subtotal2": format_rupiah(parseFloat(cari_harga_beli)) });
                        }
                    }
                    else {
                        tampung.push({ "id_jenis": cari_id_jenis, "jenis": cari_jenis, "id_produk": cari_id_produk, "nama": cari_nama, "harga_beli": cari_harga_beli, "harga_beli2": cari_harga_beli2, "satuan": cari_satuan, "jumlah": 1, "subtotal": cari_harga_beli, "subtotal2": format_rupiah(parseFloat(cari_harga_beli)) });
                    }

                    let total = 0;
                    awal = 1;
                    akhir = tampung.length;
                    while (awal <= akhir) {
                        total = total + parseFloat(tampung[awal - 1]["subtotal"]);
                        awal++;
                    }

                    setDataBelanja(tampung);
                    setTotal(total);
                    hitung_pembulatan(total, diskon);
                    setKode("");
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
            }
            );
    }

    const tampil_produk = (halaman_baru) => {
        fetch(props.aplikasi + '/produk/tampil_produk_limit.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: "",
                        cari: pencarian,
                        jumlah_baris: jumlah_baris_produk,
                        halaman: halaman_baru
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataProduk(data.data);
                    setJumlahHalamanProduk(Math.ceil(data.jumlah_data / jumlah_baris_produk));
                }
                else {
                    setDataProduk([]);
                    setJumlahHalamanProduk(0);
                }
            })
            .catch(error => {
                setDataProduk([]);
                setJumlahHalamanProduk(0);
            }
            );
    }

    const pindah_halaman_produk = (halaman_produk_baru) => {
        setHalamanProduk(halaman_produk_baru);
        tampil_produk(halaman_produk_baru);
    }

    const cari_produk = () => {
        setHalamanProduk(1);
        tampil_produk(1);
    }

    const tampil_draft = (halaman_baru) => {
        fetch(props.aplikasi + '/produk/tampil_draft_limit.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        jumlah_baris: jumlah_baris_draft,
                        halaman: halaman_baru
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataDraft(data.data);
                    setJumlahHalamanDraft(Math.ceil(data.jumlah_data / jumlah_baris_draft));
                }
                else {
                    setDataDraft([]);
                    setJumlahHalamanDraft(0);
                }
            })
            .catch(error => {
                setDataDraft([]);
                setJumlahHalamanDraft(0);
            }
            );
    }

    const pindah_halaman_draft = (halaman_draft_baru) => {
        setHalamanDraft(halaman_draft_baru);
        tampil_draft(halaman_draft_baru);
    }

    const ubah_harga_beli = (event, id_produk) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }

        let tampung = [...data_belanja];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_produk"] === id_produk) {
                tampung[awal - 1]["harga_beli"] = event.target.value;
                tampung[awal - 1]["subtotal"] = parseFloat(tampung[awal - 1]["jumlah"]) * parseFloat(tampung[awal - 1]["harga_beli"]);
                tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                break;
            }
            awal++;
        }

        let tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataBelanja(tampung);
        setTotal(tampung_total);
        hitung_pembulatan(tampung_total, diskon);
    }

    const perbaiki_harga_beli = (event, id_produk) => {
        let tampung = [...data_belanja];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_produk"] === id_produk) {
                if (parseFloat(event.target.value) > 0) {
                    tampung[awal - 1]["harga_beli"] = event.target.value;
                }
                else {
                    tampung[awal - 1]["harga_beli"] = 1;
                }

                tampung[awal - 1]["subtotal"] = parseFloat(tampung[awal - 1]["jumlah"]) * parseFloat(tampung[awal - 1]["harga_beli"]);
                tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                break;
            }
            awal++;
        }

        let tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataBelanja(tampung);
        setTotal(tampung_total);
        hitung_pembulatan(tampung_total, diskon);
    }

    const tambah_belanja = (id_produk) => {
        let tampung = [...data_belanja];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_produk"] === id_produk) {
                tampung[awal - 1]["jumlah"] = parseFloat(tampung[awal - 1]["jumlah"]) + 1;
                tampung[awal - 1]["subtotal"] = parseFloat(tampung[awal - 1]["jumlah"]) * parseFloat(tampung[awal - 1]["harga_beli"]);
                tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                break;
            }
            awal++;
        }

        let tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataBelanja(tampung);
        setTotal(tampung_total);
        hitung_pembulatan(tampung_total, diskon);
    }

    const kurang_belanja = (id_produk) => {
        let tampung = [...data_belanja];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_produk"] === id_produk) {
                if (parseFloat(tampung[awal - 1]["jumlah"]) - 1 < 1) {
                    return;
                }
                tampung[awal - 1]["jumlah"] = parseFloat(tampung[awal - 1]["jumlah"]) - 1;
                tampung[awal - 1]["subtotal"] = parseFloat(tampung[awal - 1]["jumlah"]) * parseFloat(tampung[awal - 1]["harga_beli"]);
                tampung[awal - 1]["subtotal2"] = format_rupiah(parseFloat(tampung[awal - 1]["subtotal"]));
                break;
            }
            awal++;
        }

        let tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataBelanja(tampung);
        setTotal(tampung_total);
        hitung_pembulatan(tampung_total, diskon);
    }

    const ubah_jumlah_belanja = (event, id_produk) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }

        let tampung = [...data_belanja];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_produk"] === id_produk) {
                tampung[awal - 1]["jumlah"] = event.target.value;
                tampung[awal - 1]["subtotal"] = parseFloat(tampung[awal - 1]["jumlah"]) * parseFloat(tampung[awal - 1]["harga_beli"]);
                tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                break;
            }
            awal++;
        }

        let tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataBelanja(tampung);
        setTotal(tampung_total);
        hitung_pembulatan(tampung_total, diskon);
    }

    const perbaiki_belanja = (event, id_produk) => {
        let tampung = [...data_belanja];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_produk"] === id_produk) {
                if (parseFloat(event.target.value) > 0) {
                    tampung[awal - 1]["jumlah"] = event.target.value;
                }
                else {
                    tampung[awal - 1]["jumlah"] = 1;
                }

                tampung[awal - 1]["subtotal"] = parseFloat(tampung[awal - 1]["jumlah"]) * parseFloat(tampung[awal - 1]["harga_beli"]);
                tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                break;
            }
            awal++;
        }

        let tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataBelanja(tampung);
        setTotal(tampung_total);
        hitung_pembulatan(tampung_total, diskon);
    }

    const hapus_belanja = (id_produk) => {
        let tampung = [...data_belanja];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_produk"] === id_produk) {
                delete tampung[awal - 1];
                break;
            }
            awal++;
        }

        tampung = tampung.filter(function (element) {
            return element !== undefined;
        });

        let tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataBelanja(tampung);
        setTotal(tampung_total);
        hitung_pembulatan(tampung_total, diskon);
    }

    const ubah_diskon = (event) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }

        setDiskon(event.target.value);
        hitung_pembulatan(total, event.target.value);
    }

    const perbaiki_diskon = (event) => {
        if (event.target.value === "") {
            setDiskon(0);
            setPembulatan(0);
            setGrandTotal(total);
            return;
        }

        let tampung_grand_total = parseFloat(total) - parseFloat(event.target.value);
        let tampung_pembulatan = Math.round(tampung_grand_total / 100) * 100 - tampung_grand_total;

        setDiskon(event.target.value);
        setPembulatan(tampung_pembulatan);
        setGrandTotal(total - event.target.value + tampung_pembulatan);
    }

    const batal = () => {
        fetch(props.aplikasi + '/kasir/tampil_metode_bayar.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataMetodeBayar(data.data);
                }
                else {
                    setDataMetodeBayar([]);
                }
            })
            .catch(error => {
                setDataMetodeBayar([]);
            }
            );

        fetch(props.aplikasi + '/supplier/tampil_supplier.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: "1"
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataSupplier(data.data);
                }
                else {
                    setDataSupplier([]);
                }
            })
            .catch(error => {
                setDataSupplier([]);
            }
            );

        setKode("");
        setDataBelanja([]);
        setJumlahMetodeBayar(1);
        setMetodeBayar("1");
        setMetodeBayar2("");
        setJumlahBayar(0);
        setJumlahBayar2(0);
        setTanggalJatuhTempo(null);
        setTanggalPembelian(new Date());
        setNoFaktur("");
        setSupplier(null);
        setInputSupplier("");
        setTotal(0);
        setDiskon(0);
        setPembulatan(0);
        setGrandTotal(0);
        setKembalian(0);
        setIdDraft("");
    }

    const ubah_jumlah_bayar = (event) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }
        setJumlahBayar(event.target.value);
        setKembalian(parseFloat(event.target.value) + parseFloat(jumlah_bayar2) - parseFloat(grand_total));
    }

    const perbaiki_jumlah_bayar = (event) => {
        if (parseFloat(event.target.value) > 0) {
            setJumlahBayar(event.target.value);
            setKembalian(parseFloat(event.target.value) + parseFloat(jumlah_bayar2) - parseFloat(grand_total));
        }
        else {
            setJumlahBayar(0);
            setKembalian(parseFloat(jumlah_bayar2) - parseFloat(grand_total));
        }
    }

    const ubah_jumlah_bayar2 = (event) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }
        setJumlahBayar2(event.target.value);
        setKembalian(parseFloat(jumlah_bayar) + parseFloat(event.target.value) - parseFloat(grand_total));
    }

    const perbaiki_jumlah_bayar2 = (event) => {
        if (parseFloat(event.target.value) > 0) {
            setJumlahBayar2(event.target.value);
            setKembalian(parseFloat(jumlah_bayar) + parseFloat(event.target.value) - parseFloat(grand_total));
        }
        else {
            setJumlahBayar2(0);
            setKembalian(parseFloat(jumlah_bayar) - parseFloat(grand_total));
        }
    }

    const simpan = () => {
        let id_supplier = "";
        let nama_supplier = "";
        if (supplier !== null) {
            id_supplier = supplier.id_supplier;
            nama_supplier = supplier.nama;
        }

        if (no_faktur === "") {
            setJenisNotif("warning");
            setIsiNotif("No. Faktur wajib diisi");
            setTampilNotif(true);
            document.getElementById("no_faktur").focus();
            return;
        }

        if (tanggal_pembelian === null) {
            setJenisNotif("warning");
            setIsiNotif("Tanggal pembelian wajib diisi");
            setTampilNotif(true);
            document.getElementById("tanggal_pembelian").focus();
            return;
        }

        if (id_supplier === "") {
            setJenisNotif("warning");
            setIsiNotif("Supplier wajib diisi");
            setTampilNotif(true);
            return;
        }

        if (metode_bayar !== "99" && parseFloat(jumlah_bayar) === 0) {
            setJenisNotif("warning");
            setIsiNotif("Jumlah bayar wajib diisi");
            setTampilNotif(true);
            document.getElementById("jumlah_bayar").focus();
            return;
        }

        if (metode_bayar2 !== "99" && jumlah_metode_bayar === 2 && jumlah_bayar2 === 0) {
            setJenisNotif("warning");
            setIsiNotif("Jumlah bayar Kedua wajib diisi");
            setTampilNotif(true);
            document.getElementById("jumlah_bayar2").focus();
            return;
        }

        if ((metode_bayar !== "99" && metode_bayar2 !== "99") && jumlah_metode_bayar === 2 && kembalian !== 0) {
            setJenisNotif("warning");
            setIsiNotif("Bila menggunakan 2 metode pembayaran, uang harus pas");
            setTampilNotif(true);
            document.getElementById("jumlah_bayar").focus();
            return;
        }

        if ((metode_bayar === "99" || metode_bayar2 === "99") && tanggal_jatuh_tempo === null) {
            setJenisNotif("warning");
            setIsiNotif("Bila menggunakan metode hutang, tanggal jatuh tempo wajib diisi");
            setTampilNotif(true);
            document.getElementById("tanggal_jatuh_tempo").focus();
            return;
        }

        let tahun = String(tanggal_pembelian.getFullYear());
        let bulan = String(tanggal_pembelian.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        let tanggal = String(tanggal_pembelian.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        let format_tanggal_pembelian = tahun + "-" + bulan + "-" + tanggal;

        let format_tanggal_jatuh_tempo = "";
        if (tanggal_jatuh_tempo !== null) {
            tahun = String(tanggal_jatuh_tempo.getFullYear());
            bulan = String(tanggal_jatuh_tempo.getMonth() + 1);
            if (bulan.length === 1) {
                bulan = "0" + bulan;
            }
            tanggal = String(tanggal_jatuh_tempo.getDate());
            if (tanggal.length === 1) {
                tanggal = "0" + tanggal;
            }

            format_tanggal_jatuh_tempo = tahun + "-" + bulan + "-" + tanggal;
        }

        fetch(props.aplikasi + '/produk/simpan_pembelian.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_draft: id_draft,
                        no_faktur: no_faktur,
                        tanggal_pembelian: format_tanggal_pembelian,
                        id_supplier: id_supplier,
                        nama_supplier: nama_supplier,
                        id_metode_bayar: metode_bayar,
                        id_metode_bayar2: metode_bayar2,
                        subtotal: total,
                        diskon: diskon,
                        pembulatan: pembulatan,
                        grand_total: grand_total,
                        jumlah_bayar: jumlah_bayar,
                        jumlah_bayar2: jumlah_bayar2,
                        kembalian: kembalian,
                        tanggal_jatuh_tempo: format_tanggal_jatuh_tempo,
                        data_belanja: data_belanja,
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setTampilDialogBayar(false);
                    batal();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
            }
            );
    }

    const simpan_draft = () => {
        if (data_belanja.length === 0) {
            setJenisNotif("warning");
            setIsiNotif("Produk belum dipilih");
            setTampilNotif(true);
            return;
        }

        let id_supplier = "";
        let nama_supplier = "";
        if (supplier !== null) {
            id_supplier = supplier.id_supplier;
            nama_supplier = supplier.nama;
        }

        let format_tanggal_pembelian = "";
        if (tanggal_pembelian !== null) {
            let tahun = String(tanggal_pembelian.getFullYear());
            let bulan = String(tanggal_pembelian.getMonth() + 1);
            if (bulan.length === 1) {
                bulan = "0" + bulan;
            }
            let tanggal = String(tanggal_pembelian.getDate());
            if (tanggal.length === 1) {
                tanggal = "0" + tanggal;
            }

            format_tanggal_pembelian = tahun + "-" + bulan + "-" + tanggal;
        }

        let format_tanggal_jatuh_tempo = "";
        if (tanggal_jatuh_tempo !== null) {
            let tahun = String(tanggal_jatuh_tempo.getFullYear());
            let bulan = String(tanggal_jatuh_tempo.getMonth() + 1);
            if (bulan.length === 1) {
                bulan = "0" + bulan;
            }
            let tanggal = String(tanggal_jatuh_tempo.getDate());
            if (tanggal.length === 1) {
                tanggal = "0" + tanggal;
            }

            format_tanggal_jatuh_tempo = tahun + "-" + bulan + "-" + tanggal;
        }

        fetch(props.aplikasi + '/produk/simpan_pembelian_draft.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_draft: id_draft,
                        no_faktur: no_faktur,
                        tanggal_pembelian: format_tanggal_pembelian,
                        id_supplier: id_supplier,
                        nama_supplier: nama_supplier,
                        id_metode_bayar: metode_bayar,
                        id_metode_bayar2: metode_bayar2,
                        subtotal: total,
                        diskon: diskon,
                        pembulatan: pembulatan,
                        grand_total: grand_total,
                        jumlah_bayar: 0,
                        jumlah_bayar2: 0,
                        kembalian: -parseFloat(grand_total),
                        tanggal_jatuh_tempo: format_tanggal_jatuh_tempo,
                        data_belanja: data_belanja,
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setTampilDialogBayar(false);
                    batal();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
            }
            );
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Dialog
                open={tampil_dialog_diskon}
                onClose={() => setTampilDialogDiskon(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Input Diskon
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Silakan masukkan jumlah diskon
                    </DialogContentText>

                    <TextField
                        id="diskon"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={diskon}
                        inputProps={{
                            style: { textAlign: "right" }
                        }}
                        onChange={(e) => ubah_diskon(e)}
                        onBlur={(e) => perbaiki_diskon(e)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        onClick={() => setTampilDialogDiskon(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_cari_produk}
                onClose={() => setTampilDialogCariProduk(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
            >
                <DialogTitle id="alert-dialog-title">
                    Pencarian Produk
                </DialogTitle>
                <DialogContent>
                    <TextField
                        id="pencarian"
                        label="Cari nama produk disini"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={pencarian}
                        sx={{
                            marginTop: "10px",
                            marginBottom: "10px"
                        }}
                        onChange={(event) => setPencarian(event.target.value)}
                        onKeyPress={(event) => {
                            if (event.key === "Enter") {
                                cari_produk();
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchOutlined sx={{ color: "#219cd9" }} />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <Card
                        sx={{
                            backgroundColor: "white",
                            padding: "10px"
                        }}
                    >
                        <TableContainer component={Paper}>
                            <Table stickyHeader size="small" aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">Nama</StyledTableCell>
                                        <StyledTableCell align="center">Harga</StyledTableCell>
                                        <StyledTableCell align="center">Stok</StyledTableCell>
                                        <StyledTableCell align="center">Satuan</StyledTableCell>
                                        <StyledTableCell align="center">Aksi</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data_produk.map((tampung_produk) => {
                                        return (
                                            <StyledTableRow key={tampung_produk.id_produk}>
                                                <TableCell size="small"><Typography variant="body2" noWrap={true}>{tampung_produk.nama}</Typography></TableCell>
                                                <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{tampung_produk.harga_beli}</Typography></TableCell>
                                                <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{tampung_produk.jumlah2}</Typography></TableCell>
                                                <TableCell size="small" align="center"><Typography variant="body2" noWrap={true}>{tampung_produk.satuan}</Typography></TableCell>
                                                <TableCell size="small" align="center">
                                                    <Fab
                                                        color="primary"
                                                        size="small"
                                                        style={{ backgroundColor: "gray" }}
                                                        onClick={() => {
                                                            let cari_id_jenis = tampung_produk.id_jenis;
                                                            let cari_jenis = tampung_produk.jenis;
                                                            let cari_id_produk = tampung_produk.id_produk;
                                                            let cari_nama = tampung_produk.nama;
                                                            let cari_harga_beli = tampung_produk.harga_beli;
                                                            let cari_harga_beli2 = tampung_produk.harga_beli2;
                                                            let cari_satuan = tampung_produk.satuan;

                                                            let tampung = [...data_belanja];
                                                            let awal = 1;
                                                            let akhir = tampung.length;
                                                            let cari = "";

                                                            if (akhir > 0) {
                                                                while (awal <= akhir) {
                                                                    if (cari_id_produk === tampung[awal - 1]["id_produk"]) {
                                                                        tampung[awal - 1]["harga_beli"] = cari_harga_beli;
                                                                        tampung[awal - 1]["harga_beli2"] = cari_harga_beli2;
                                                                        tampung[awal - 1]["satuan"] = cari_satuan;
                                                                        tampung[awal - 1]["jumlah"] = parseFloat(tampung[awal - 1]["jumlah"]) + 1;
                                                                        tampung[awal - 1]["subtotal"] = parseFloat(tampung[awal - 1]["jumlah"]) * parseFloat(tampung[awal - 1]["harga_beli"]);
                                                                        tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                                                                        cari = "ada";
                                                                        break;
                                                                    }
                                                                    awal++;
                                                                }

                                                                if (cari === "") {
                                                                    tampung.push({ "id_jenis": cari_id_jenis, "jenis": cari_jenis, "id_produk": cari_id_produk, "nama": cari_nama, "harga_beli": cari_harga_beli, "harga_beli2": cari_harga_beli2, "satuan": cari_satuan, "jumlah": 1, "subtotal": cari_harga_beli, "subtotal2": format_rupiah(parseFloat(cari_harga_beli)) });
                                                                }
                                                            }
                                                            else {
                                                                tampung.push({ "id_jenis": cari_id_jenis, "jenis": cari_jenis, "id_produk": cari_id_produk, "nama": cari_nama, "harga_beli": cari_harga_beli, "harga_beli2": cari_harga_beli2, "satuan": cari_satuan, "jumlah": 1, "subtotal": cari_harga_beli, "subtotal2": format_rupiah(parseFloat(cari_harga_beli)) });
                                                            }

                                                            let total = 0;
                                                            awal = 1;
                                                            akhir = tampung.length;
                                                            while (awal <= akhir) {
                                                                total = total + parseFloat(tampung[awal - 1]["subtotal"]);
                                                                awal++;
                                                            }

                                                            setDataBelanja(tampung);
                                                            setTotal(total);
                                                            hitung_pembulatan(total, diskon);
                                                            setKode("");
                                                        }}
                                                    >
                                                        <AddIcon />
                                                    </Fab>
                                                </TableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Stack spacing={2}
                            sx={{
                                marginTop: "10px"
                            }}
                        >
                            <Pagination
                                color="primary"
                                count={jumlah_halaman_produk}
                                page={halaman_produk}
                                onChange={(event, nomor) => {
                                    pindah_halaman_produk(nomor);
                                }}
                            />
                        </Stack>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        onClick={() => setTampilDialogCariProduk(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_draft}
                onClose={() => setTampilDialogDraft(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
            >
                <DialogTitle id="alert-dialog-title">
                    Daftar Draft
                </DialogTitle>
                <DialogContent>
                    <Card
                        sx={{
                            backgroundColor: "white",
                            padding: "10px"
                        }}
                    >
                        <TableContainer component={Paper}>
                            <Table stickyHeader size="small" aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">No. Faktur</StyledTableCell>
                                        <StyledTableCell align="center">Tgl. Pembelian</StyledTableCell>
                                        <StyledTableCell align="center">Supplier</StyledTableCell>
                                        <StyledTableCell align="center">Metode Bayar 1</StyledTableCell>
                                        <StyledTableCell align="center">Metode Bayar 2</StyledTableCell>
                                        <StyledTableCell align="center">Aksi</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data_draft.map((tampung_draft) => {
                                        return (
                                            <StyledTableRow key={tampung_draft.id_draft}>
                                                <TableCell size="small"><Typography variant="body2" noWrap={true}>{tampung_draft.no_faktur}</Typography></TableCell>
                                                <TableCell size="small" align="center"><Typography variant="body2" noWrap={true}>{tampung_draft.tanggal_pembelian}</Typography></TableCell>
                                                <TableCell size="small"><Typography variant="body2" noWrap={true}>{tampung_draft.nama_supplier}</Typography></TableCell>
                                                <TableCell size="small"><Typography variant="body2" noWrap={true}>{tampung_draft.metode_bayar}</Typography></TableCell>
                                                <TableCell size="small"><Typography variant="body2" noWrap={true}>{tampung_draft.metode_bayar2}</Typography></TableCell>
                                                <TableCell size="small" align="center">
                                                    <Fab
                                                        color="primary"
                                                        size="small"
                                                        style={{ backgroundColor: "gray", marginRight: "5px" }}
                                                        onClick={() => {
                                                            setIdDraft(tampung_draft.id_draft);
                                                            setMetodeBayar(tampung_draft.id_metode_bayar);

                                                            if (tampung_draft.id_metode_bayar2 !== "0") {
                                                                setJumlahMetodeBayar(2);
                                                                setMetodeBayar2(tampung_draft.id_metode_bayar2);
                                                            }
                                                            else {
                                                                setJumlahMetodeBayar(1);
                                                                setMetodeBayar2("");
                                                            }

                                                            if (tampung_draft.tanggal_jatuh_tempo !== "0000-00-00") {
                                                                setTanggalJatuhTempo(new Date(tampung_draft.tanggal_jatuh_tempo));
                                                            }
                                                            else {
                                                                setTanggalJatuhTempo(null);
                                                            }

                                                            if (tampung_draft.tanggal_pembelian !== "0000-00-00") {
                                                                setTanggalPembelian(new Date(tampung_draft.tanggal_pembelian));
                                                            }
                                                            else {
                                                                setTanggalPembelian(null);
                                                            }

                                                            setNoFaktur(tampung_draft.no_faktur);
                                                            if (tampung_draft.id_supplier !== "0") {
                                                                let tampung = [...data_supplier];
                                                                let awal = 1;
                                                                let akhir = tampung.length;
                                                                while (awal <= akhir) {
                                                                    if (tampung_draft.id_supplier === tampung[awal - 1]["id_supplier"]) {
                                                                        setSupplier(tampung[awal - 1]);
                                                                        break;
                                                                    }
                                                                    awal++;
                                                                }
                                                            }
                                                            else {
                                                                setSupplier(null);
                                                                setInputSupplier("");
                                                            }
                                                            setTotal(parseFloat(tampung_draft.subtotal));
                                                            setDiskon(parseFloat(tampung_draft.diskon));
                                                            setPembulatan(parseFloat(tampung_draft.pembulatan));
                                                            setGrandTotal(parseFloat(tampung_draft.grand_total));

                                                            fetch(props.aplikasi + '/produk/tampil_draft_detail.php',
                                                                {
                                                                    method: 'POST',
                                                                    body: JSON.stringify
                                                                        ({
                                                                            id_draft: tampung_draft.id_draft
                                                                        }),
                                                                    headers:
                                                                    {
                                                                        "Content-type": "application/json; charset=UTF-8"
                                                                    }
                                                                })
                                                                .then(response => response.json())
                                                                .then(data => {
                                                                    if (data.kode === 1) {
                                                                        let tampung = [];
                                                                        let awal = 1;
                                                                        let akhir = data.data.length;
                                                                        while (awal <= akhir) {
                                                                            let cari_id_jenis = data.data[awal - 1]["id_jenis"];
                                                                            let cari_jenis = data.data[awal - 1]["jenis"];
                                                                            let cari_id_produk = data.data[awal - 1]["id_produk"];
                                                                            let cari_nama = data.data[awal - 1]["nama"];
                                                                            let cari_harga_beli = data.data[awal - 1]["harga_beli"];
                                                                            let cari_harga_beli2 = data.data[awal - 1]["harga_beli2"];
                                                                            let cari_jumlah = data.data[awal - 1]["jumlah"];
                                                                            let cari_satuan = data.data[awal - 1]["satuan"];

                                                                            tampung.push({ "id_jenis": cari_id_jenis, "jenis": cari_jenis, "id_produk": cari_id_produk, "nama": cari_nama, "harga_beli": cari_harga_beli, "harga_beli2": cari_harga_beli2, "satuan": cari_satuan, "jumlah": cari_jumlah, "subtotal": cari_harga_beli, "subtotal2": format_rupiah(parseFloat(cari_jumlah) * parseFloat(cari_harga_beli)) });
                                                                            awal++;
                                                                        }

                                                                        setDataBelanja(tampung);
                                                                        setTampilDialogDraft(false);
                                                                    }
                                                                    else {
                                                                        setDataBelanja([]);
                                                                    }
                                                                })
                                                                .catch(error => {
                                                                    setDataBelanja([]);
                                                                }
                                                                );
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </Fab>

                                                    <Fab
                                                        color="primary"
                                                        size="small"
                                                        style={{ backgroundColor: "#8080FF", marginRight: "5px" }}
                                                        onClick={() => {
                                                            window.open(props.aplikasi + '/produk/cetak_pembelian_draft.php?id_draft=' + tampung_draft.id_draft);
                                                        }}
                                                    >
                                                        <PrintIcon />
                                                    </Fab>

                                                    <Fab
                                                        color="primary"
                                                        size="small"
                                                        style={{ backgroundColor: "red" }}
                                                        onClick={() => {
                                                            setIdDraftHapus(tampung_draft.id_draft);
                                                            setTampilDialogHapusDraft(true);
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </Fab>
                                                </TableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Stack spacing={2}
                            sx={{
                                marginTop: "10px"
                            }}
                        >
                            <Pagination
                                color="primary"
                                count={jumlah_halaman_draft}
                                page={halaman_draft}
                                onChange={(event, nomor) => {
                                    pindah_halaman_draft(nomor);
                                }}
                            />
                        </Stack>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        onClick={() => setTampilDialogDraft(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_hapus_draft}
                onClose={() => setTampilDialogHapusDraft(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Hapus Draft
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin menghapus draft ini?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setTampilDialogHapusDraft(false)}
                        autoFocus
                    >
                        Tidak
                    </Button>
                    <Button
                        onClick={() => {
                            fetch(props.aplikasi + '/produk/hapus_draft.php',
                                {
                                    method: 'POST',
                                    body: JSON.stringify
                                        ({
                                            id_draft: id_draft_hapus
                                        }),
                                    headers:
                                    {
                                        "Content-type": "application/json; charset=UTF-8"
                                    }
                                })
                                .then(response => response.json())
                                .then(data => {
                                    if (data.kode === 1) {
                                        setJenisNotif("success");
                                        setIsiNotif(data.pesan);
                                        setTampilNotif(true);
                                        setTampilDialogHapusDraft(false);
                                        setTampilDialogDraft(false);
                                    }
                                    else {
                                        setJenisNotif("warning");
                                        setIsiNotif(data.pesan);
                                        setTampilNotif(true);
                                    }
                                })
                                .catch(error => {
                                    setJenisNotif("error");
                                    setIsiNotif("Terjadi kesalahan");
                                    setTampilNotif(true);
                                }
                                );
                        }}
                    >
                        Ya
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_batal}
                onClose={() => setTampilDialogBatal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Batal Transaksi
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin membatalkan transaksi ini?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setTampilDialogBatal(false)}
                        autoFocus
                    >
                        Tidak
                    </Button>
                    <Button
                        onClick={() => {
                            setTampilDialogBatal(false);
                            batal();
                        }}
                    >
                        Ya
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_bayar}
            >
                <DialogTitle>Pembayaran</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={12} sm={12} md={3}>

                                </Grid>

                                <Grid container item spacing={1} xs={12} sm={12} md={9}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="body2" align="right">
                                            Tagihan
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="h4" align="right" sx={{ color: "red" }}>
                                            Rp. {format_rupiah(grand_total)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={12} md={3}>
                                    <Typography variant="body2">
                                        Jumlah Bayar
                                    </Typography>
                                </Grid>

                                <Grid container item spacing={1} xs={12} sm={12} md={9}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="jumlah_bayar"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            multiline
                                            disabled={metode_bayar === "99" ? true : false}
                                            value={jumlah_bayar}
                                            onChange={(e) => ubah_jumlah_bayar(e)}
                                            onBlur={(e) => perbaiki_jumlah_bayar(e)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="h6" align="right" sx={{ color: "green" }}>
                                            Rp. {format_rupiah(parseFloat(jumlah_bayar))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item
                                display={jumlah_metode_bayar === 2 ? "" : "none"}
                            >
                                <Grid item xs={12} sm={12} md={3}>
                                    <Typography variant="body2">
                                        Jumlah Bayar Kedua
                                    </Typography>
                                </Grid>

                                <Grid container item spacing={1} xs={12} sm={12} md={9}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="jumlah_bayar2"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            multiline
                                            disabled={metode_bayar2 === "99" ? true : false}
                                            value={jumlah_bayar2}
                                            onChange={(e) => ubah_jumlah_bayar2(e)}
                                            onBlur={(e) => perbaiki_jumlah_bayar2(e)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="h6" align="right" sx={{ color: "green" }}>
                                            Rp. {format_rupiah(parseFloat(jumlah_bayar2))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={12} md={3}>

                                </Grid>

                                <Grid container item spacing={1} xs={12} sm={12} md={9}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="body2" align="right">
                                            Kembalian
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="h6" align="right" sx={{ color: "red" }}>
                                            Rp. {format_rupiah(kembalian)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AttachMoneyIcon />}
                        sx={{
                            marginRight: "5px", marginTop: "5px", borderRadius: "5em"
                        }}
                        onClick={() => simpan()}
                    >
                        Bayar
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloseIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        onClick={() => {
                            setTampilDialogBayar(false);
                        }}
                    >
                        Tutup
                    </Button>
                </DialogActions>
            </Dialog>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Card
                                    sx={{
                                        backgroundColor: "white",
                                        padding: "10px"
                                    }}
                                >
                                    <TextField
                                        id="cari"
                                        label="Scan barcode disini atau klik icon disisi kanan untuk melakukan pencarian"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        value={kode}
                                        onChange={(event) => setKode(event.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        edge="end"
                                                        onClick={() => {
                                                            fetch(props.aplikasi + '/produk/tampil_produk_limit.php',
                                                                {
                                                                    method: 'POST',
                                                                    body: JSON.stringify
                                                                        ({
                                                                            status: "1",
                                                                            cari: "",
                                                                            jumlah_baris: jumlah_baris_produk,
                                                                            halaman: 1
                                                                        }),
                                                                    headers:
                                                                    {
                                                                        "Content-type": "application/json; charset=UTF-8"
                                                                    }
                                                                })
                                                                .then(response => response.json())
                                                                .then(data => {
                                                                    if (data.kode === 1) {
                                                                        setDataProduk(data.data);
                                                                        setJumlahHalamanProduk(Math.ceil(data.jumlah_data / jumlah_baris_produk));
                                                                        setHalamanProduk(1);
                                                                        setPencarian("");
                                                                        setTampilDialogCariProduk(true)
                                                                    }
                                                                    else {
                                                                        setDataProduk([]);
                                                                        setJumlahHalamanProduk(0);
                                                                        setHalamanProduk(0);
                                                                    }
                                                                })
                                                                .catch(error => {
                                                                    setDataProduk([]);
                                                                    setJumlahHalamanProduk(0);
                                                                    setHalamanProduk(0);
                                                                }
                                                                );
                                                        }}
                                                    >
                                                        <SearchOutlined sx={{ color: "#219cd9" }} />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        onKeyPress={(event) => {
                                            if (event.key === "Enter") {
                                                cari();
                                            }
                                        }}
                                    />
                                </Card>
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <Card
                                    sx={{
                                        backgroundColor: "white",
                                        padding: "10px",
                                        display: "flex",
                                        overflowY: "auto",
                                        maxHeight: "calc(100vh - 155px)" 
                                    }}
                                >
                                    <TableContainer component={Paper}>
                                        <Table stickyHeader size="small" aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="center">Nama</StyledTableCell>
                                                    <StyledTableCell align="center">Harga</StyledTableCell>
                                                    <StyledTableCell align="center">Jumlah</StyledTableCell>
                                                    <StyledTableCell align="center">Satuan</StyledTableCell>
                                                    <StyledTableCell align="center">Subtotal</StyledTableCell>
                                                    <StyledTableCell align="center">Aksi</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data_belanja.map((tampung_belanja) => {
                                                    return (
                                                        <StyledTableRow key={tampung_belanja.id_produk}>
                                                            <TableCell size="small"><Typography variant="body2" noWrap={true}>{tampung_belanja.nama}</Typography></TableCell>
                                                            <TableCell size="small" align="right">
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    inputProps={{
                                                                        style: {
                                                                            textAlign: "right",
                                                                            minWidth: "100px"
                                                                        }
                                                                    }}
                                                                    value={tampung_belanja.harga_beli}
                                                                    onChange={(e) => ubah_harga_beli(e, tampung_belanja.id_produk)}
                                                                    onBlur={(e) => perbaiki_harga_beli(e, tampung_belanja.id_produk)}
                                                                />
                                                            </TableCell>
                                                            <TableCell size="small" align="center" sx={{ whiteSpace: "nowrap" }}>
                                                                <Fab
                                                                    color="primary"
                                                                    size="small"
                                                                    style={{ backgroundColor: "gray" }}
                                                                    onClick={() => kurang_belanja(tampung_belanja.id_produk)}
                                                                >
                                                                    <RemoveIcon />
                                                                </Fab>
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    inputProps={{
                                                                        style: {
                                                                            textAlign: "center",
                                                                            width: "50px"
                                                                        }
                                                                    }}
                                                                    value={tampung_belanja.jumlah}
                                                                    onChange={(e) => ubah_jumlah_belanja(e, tampung_belanja.id_produk)}
                                                                    onBlur={(e) => perbaiki_belanja(e, tampung_belanja.id_produk)}
                                                                />
                                                                <Fab
                                                                    color="primary"
                                                                    size="small"
                                                                    style={{ backgroundColor: "gray" }}
                                                                    onClick={() => tambah_belanja(tampung_belanja.id_produk)}
                                                                >
                                                                    <AddIcon />
                                                                </Fab>
                                                            </TableCell>
                                                            <TableCell size="small" align="center"><Typography variant="body2" noWrap={true}>{tampung_belanja.satuan}</Typography></TableCell>
                                                            <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{tampung_belanja.subtotal2}</Typography></TableCell>
                                                            <TableCell size="small" align="center">
                                                                <Fab
                                                                    color="primary"
                                                                    size="small"
                                                                    style={{ backgroundColor: "red" }}
                                                                    onClick={() => hapus_belanja(tampung_belanja.id_produk)}
                                                                >
                                                                    <DeleteIcon />
                                                                </Fab>
                                                            </TableCell>
                                                        </StyledTableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Card>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Card
                                            sx={{
                                                backgroundColor: "white",
                                                padding: "10px"
                                            }}
                                        >
                                            <Grid container item spacing={1}>
                                                <Grid container item>
                                                    <Grid item xs={12} sm={4} md={3}>

                                                    </Grid>

                                                    <Grid item xs={12} sm={8} md={9}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            startIcon={<ListIcon />}
                                                            sx={{ marginRight: "5px", marginBottom: "10px", borderRadius: "5em", backgroundColor: "#ff93b7" }}
                                                            onClick={() => {
                                                                fetch(props.aplikasi + '/produk/tampil_draft_limit.php',
                                                                    {
                                                                        method: 'POST',
                                                                        body: JSON.stringify
                                                                            ({
                                                                                jumlah_baris: jumlah_baris_draft,
                                                                                halaman: 1
                                                                            }),
                                                                        headers:
                                                                        {
                                                                            "Content-type": "application/json; charset=UTF-8"
                                                                        }
                                                                    })
                                                                    .then(response => response.json())
                                                                    .then(data => {
                                                                        if (data.kode === 1) {
                                                                            setDataDraft(data.data);
                                                                            setJumlahHalamanDraft(Math.ceil(data.jumlah_data / jumlah_baris_draft));
                                                                            setHalamanDraft(1);
                                                                            setTampilDialogDraft(true)
                                                                        }
                                                                        else {
                                                                            setDataDraft([]);
                                                                            setJumlahHalamanDraft(0);
                                                                            setHalamanDraft(0);
                                                                        }
                                                                    })
                                                                    .catch(error => {
                                                                        setDataDraft([]);
                                                                        setJumlahHalamanDraft(0);
                                                                        setHalamanDraft(0);
                                                                    }
                                                                    );
                                                            }}
                                                        >
                                                            <Typography component={"span"} variant="h6" noWrap={true}>Daftar Draft</Typography>
                                                        </Button>
                                                    </Grid>
                                                </Grid>

                                                <Grid container item>
                                                    <Grid item xs={12} sm={4} md={3}>
                                                        <Typography variant="body2">
                                                            No. Faktur
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={8} md={9}>
                                                        <TextField
                                                            id="no_faktur"
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                            value={no_faktur}
                                                            onChange={(event) => setNoFaktur(event.target.value)}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container item>
                                                    <Grid item xs={12} sm={4} md={3}>
                                                        <Typography variant="body2">
                                                            Tanggal Pembelian
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={8} md={9}>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                id="tanggal_pembelian"
                                                                inputFormat="dd-MM-yyyy"
                                                                value={tanggal_pembelian}
                                                                onChange={(newValue) => {
                                                                    setTanggalPembelian(newValue);
                                                                }}
                                                                renderInput={(params) =>
                                                                    <TextField
                                                                        {...params}
                                                                        variant="outlined"
                                                                        size="small"
                                                                        fullWidth
                                                                    />
                                                                }
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                </Grid>

                                                <Grid container item>
                                                    <Grid item xs={12} sm={4} md={3}>
                                                        <Typography variant="body2">
                                                            Supplier
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={8} md={9}>
                                                        <Autocomplete
                                                            options={data_supplier}
                                                            getOptionLabel={
                                                                (option) => option.nama
                                                            }
                                                            inputValue={input_supplier}
                                                            value={supplier}
                                                            onInputChange={(e, nilai) => setInputSupplier(nilai)}
                                                            onChange={(e, nilai) => setSupplier(nilai)}
                                                            renderInput={
                                                                (params) =>
                                                                    <TextField
                                                                        {...params}
                                                                        key={params.id_supplier}
                                                                        variant="outlined"
                                                                        size="small"
                                                                    />
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container item>
                                                    <Grid item xs={12} sm={4} md={3}>
                                                        <Typography variant="body2">
                                                            Metode Bayar
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={8} md={9}>
                                                        <FormControl size="small" fullWidth>
                                                            <Select
                                                                id="metode_bayar"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={metode_bayar}
                                                                onChange={(event) => {
                                                                    if (jumlah_metode_bayar === 2) {
                                                                        if (event.target.value === metode_bayar2) {
                                                                            setJenisNotif("warning");
                                                                            setIsiNotif("Metode bayar harus berbeda");
                                                                            setTampilNotif(true);
                                                                            return;
                                                                        }
                                                                    }

                                                                    setMetodeBayar(event.target.value);
                                                                }}
                                                            >
                                                                {data_metode_bayar.map((tampung_metode_bayar) => {
                                                                    return (
                                                                        <MenuItem key={tampung_metode_bayar.id_metode} value={tampung_metode_bayar.id_metode}>{tampung_metode_bayar.nama}</MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>

                                                <Grid container item>
                                                    <Grid item xs={12} sm={4} md={3}>

                                                    </Grid>

                                                    <Grid item xs={12} sm={8} md={9}>
                                                        <Link
                                                            component="button"
                                                            display={jumlah_metode_bayar === 1 ? "" : "none"}
                                                            onClick={() => {
                                                                let awal = 1;
                                                                let akhir = data_metode_bayar.length;
                                                                while (awal <= akhir) {
                                                                    if (data_metode_bayar[awal - 1]["id_metode"] === metode_bayar) {
                                                                        if (parseFloat(data_metode_bayar[awal - 1]["tambah_hpp"]) > 0 || parseFloat(data_metode_bayar[awal - 1]["tambah_jual"]) > 0) {
                                                                            setJenisNotif("warning");
                                                                            setIsiNotif("Tidak dapat menambah metode bayar karena terdapat metode bayar dengan penambahan nilai jual");
                                                                            setTampilNotif(true);
                                                                            return;
                                                                        }
                                                                        break;
                                                                    }
                                                                    awal++;
                                                                }

                                                                if (jumlah_metode_bayar === 1) {
                                                                    setJumlahMetodeBayar(2);
                                                                }
                                                                else {
                                                                    setJumlahMetodeBayar(1);
                                                                }
                                                            }}
                                                        >
                                                            Tambah metode bayar
                                                        </Link>
                                                    </Grid>
                                                </Grid>

                                                <Grid container item display={jumlah_metode_bayar === 1 ? "none" : ""}>
                                                    <Grid item xs={12} sm={4} md={3}>
                                                        <Typography variant="body2">
                                                            Metode Bayar Kedua
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={8} md={9}>
                                                        <FormControl size="small" fullWidth>
                                                            <Select
                                                                id="metode_bayar2"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={metode_bayar2}
                                                                onChange={(event) => {
                                                                    if (event.target.value === metode_bayar) {
                                                                        setJenisNotif("warning");
                                                                        setIsiNotif("Metode bayar harus berbeda");
                                                                        setTampilNotif(true);
                                                                        return;
                                                                    }

                                                                    setMetodeBayar2(event.target.value);
                                                                }}
                                                            >
                                                                <MenuItem value="">Pilih Metode Bayar Kedua</MenuItem>
                                                                {data_metode_bayar.map((tampung_metode_bayar) => {
                                                                    return (
                                                                        <MenuItem key={tampung_metode_bayar.id_metode} value={tampung_metode_bayar.id_metode}>{tampung_metode_bayar.nama}</MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </FormControl>

                                                        <Link
                                                            component="button"
                                                            display={jumlah_metode_bayar === 1 ? "none" : ""}
                                                            onClick={() => {
                                                                if (jumlah_metode_bayar === 1) {
                                                                    setJumlahMetodeBayar(2);
                                                                }
                                                                else {
                                                                    setJumlahMetodeBayar(1);
                                                                }

                                                                setMetodeBayar2("");
                                                            }}
                                                        >
                                                            Hapus metode bayar
                                                        </Link>
                                                    </Grid>
                                                </Grid>

                                                <Grid container item display={metode_bayar !== "99" && metode_bayar2 !== "99" ? "none" : ""}>
                                                    <Grid item xs={12} sm={4} md={3}>
                                                        <Typography variant="body2">
                                                            Tanggal Jatuh Tempo
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={8} md={9}>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                id="tanggal_jatuh_tempo"
                                                                inputFormat="dd-MM-yyyy"
                                                                value={tanggal_jatuh_tempo}
                                                                onChange={(newValue) => {
                                                                    setTanggalJatuhTempo(newValue);
                                                                }}
                                                                renderInput={(params) =>
                                                                    <TextField
                                                                        {...params}
                                                                        variant="outlined"
                                                                        size="small"
                                                                        fullWidth
                                                                    />
                                                                }
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Card
                                            sx={{
                                                backgroundColor: "white",
                                                padding: "10px",
                                            }}
                                        >
                                            <Grid container spacing={1}>
                                                <Grid item xs={4}>
                                                    <Typography variant="h6">Total</Typography>
                                                </Grid>

                                                <Grid item xs={8}>
                                                    <Typography variant="h6" align="right" noWrap={true} sx={{ color: "green" }}>{format_rupiah(total)}</Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={1}>
                                                <Grid item xs={4} sm={8}>
                                                    <Typography variant="h6" sx={{ display: "inline", marginRight: "10px" }}>Diskon</Typography>

                                                    <IconButton
                                                        onClick={(event) => setTampilDialogDiskon(true)}
                                                        size="small"
                                                        sx={{
                                                            backgroundColor: "tomato"
                                                        }}
                                                    >
                                                        <EditIcon
                                                            sx={{
                                                                color: "white"
                                                            }}
                                                        />
                                                    </IconButton>
                                                </Grid>

                                                <Grid item xs={8} sm={4}>
                                                    <Typography variant="h6" align="right" noWrap={true} sx={{ color: "green" }}>{diskon > 0 ? "-" + format_rupiah(parseFloat(diskon)) : diskon}</Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Divider
                                                        sx={{
                                                            marginTop: "10px"
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={1}>
                                                <Grid item xs={4} sm={8}>

                                                </Grid>

                                                <Grid item xs={8} sm={4}>
                                                    <Typography variant="h6" align="right" noWrap={true} sx={{ color: "tomato" }}>{format_rupiah(parseFloat(total) - parseFloat(diskon))}</Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={1}>
                                                <Grid item xs={4}>
                                                    <Typography variant="h6">Pembulatan</Typography>
                                                </Grid>

                                                <Grid item xs={8}>
                                                    <Typography variant="h6" align="right" noWrap={true} sx={{ color: "green" }}>{format_rupiah(parseFloat(pembulatan))}</Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={1}>
                                                <Grid item xs={4}>
                                                    <Typography variant="h6">Grand Total</Typography>
                                                </Grid>

                                                <Grid item xs={8}>
                                                    <Typography variant="h4" align="right" noWrap={true} sx={{ color: "red" }}>{format_rupiah(parseFloat(grand_total))}</Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container item xs={12} justifyContent="center">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<AttachMoneyIcon />}
                                                    sx={{ marginRight: "5px", marginTop: "10px", borderRadius: "5em" }}
                                                    onClick={() => {
                                                        if (data_belanja.length > 0) {
                                                            if (jumlah_metode_bayar === 2 && metode_bayar2 === "") {
                                                                setJenisNotif("warning");
                                                                setIsiNotif("Metode bayar kedua belum diisi");
                                                                setTampilNotif(true);
                                                                return;
                                                            }

                                                            setJumlahBayar(0);
                                                            setJumlahBayar2(0);
                                                            setKembalian(-parseFloat(grand_total));
                                                            setTampilDialogBayar(true);
                                                        }
                                                    }}
                                                >
                                                    <Typography component={"span"} variant="h6" noWrap={true}>Bayar</Typography>
                                                </Button>

                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<SaveIcon />}
                                                    sx={{ marginRight: "5px", marginTop: "10px", borderRadius: "5em", backgroundColor: "#37ce9b" }}
                                                    onClick={() => {
                                                        simpan_draft();
                                                    }}
                                                >
                                                    <Typography component={"span"} variant="h6" noWrap={true}>Draft</Typography>
                                                </Button>

                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<CachedIcon />}
                                                    sx={{ marginRight: "5px", marginTop: "10px", borderRadius: "5em", backgroundColor: "tomato" }}
                                                    onClick={() => {
                                                        if (data_belanja.length > 0) {
                                                            setTampilDialogBatal(true);
                                                        }
                                                        else {
                                                            batal();
                                                        }
                                                    }}
                                                >
                                                    <Typography component={"span"} variant="h6" noWrap={true}>Batal</Typography>
                                                </Button>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default forwardRef(Pembelian);