import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

//komponen
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

//gambar
import HomeIcon from '@mui/icons-material/Home';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ReplayIcon from '@mui/icons-material/Replay';
import PaymentsIcon from '@mui/icons-material/Payments';
import HistoryIcon from '@mui/icons-material/History';
import MoneyIcon from '@mui/icons-material/Money';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

// form
import Beranda from './beranda';
import Penjualan from './penjualan';
import ReturPenjualan from './retur_penjualan';
import Piutang from './piutang';
import RiwayatPenjualan from './riwayat_penjualan';
import RiwayatRetur from './riwayat_retur';
import RiwayatAkuntansi from './riwayat_akuntansi';
import PindahSaldo from './pindah_saldo';
import Pengaturan from './pengaturan';

function Utama(props) {
    //state
    const [pilih_menu, setPilihMenu] = useState("beranda");
    const [tampil_dialog_keluar, setTampilDialogKeluar] = useState(false);

    if (!localStorage.getItem("login")) {
        return <Navigate to="/login" />;
    }
    else {
        return (
            <div>
                <Dialog
                    open={tampil_dialog_keluar}
                    onClose={() => setTampilDialogKeluar(false)}
                >
                    <DialogTitle>
                        Konfirmasi
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Apakah anda yakin ingin keluar dari aplikasi?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setTampilDialogKeluar(false)} autoFocus>Tidak</Button>
                        <Button
                            onClick={() => {
                                setTampilDialogKeluar(false);
                                props.proses_keluar();
                            }}
                        >Ya</Button>
                    </DialogActions>
                </Dialog>

                <Box sx={{ backgroundColor: "#eff0f0", height: "100vh" }}>
                    <Box sx={{ pb: 7 }}>
                        <Box
                            sx={pilih_menu === "beranda" ? { width: "100%" } : { display: "none" }}
                        >
                            <Beranda aplikasi={props.aplikasi} file={props.file} />
                        </Box>

                        <Box
                            sx={pilih_menu === "penjualan" ? { width: "100%" } : { display: "none" }}
                        >
                            <Penjualan aplikasi={props.aplikasi} file={props.file} />
                        </Box>

                        <Box
                            sx={pilih_menu === "retur_penjualan" ? { width: "100%" } : { display: "none" }}
                        >
                            <ReturPenjualan aplikasi={props.aplikasi} file={props.file} />
                        </Box>

                        <Box
                            sx={pilih_menu === "piutang" ? { width: "100%" } : { display: "none" }}
                        >
                            <Piutang aplikasi={props.aplikasi} file={props.file} />
                        </Box>

                        <Box
                            sx={pilih_menu === "riwayat_transaksi" ? { width: "100%" } : { display: "none" }}
                        >
                            <RiwayatPenjualan aplikasi={props.aplikasi} />
                        </Box>

                        <Box
                            sx={pilih_menu === "riwayat_retur" ? { width: "100%" } : { display: "none" }}
                        >
                            <RiwayatRetur aplikasi={props.aplikasi} />
                        </Box>

                        <Box
                            sx={pilih_menu === "riwayat_akuntansi" ? { width: "100%" } : { display: "none" }}
                        >
                            <RiwayatAkuntansi aplikasi={props.aplikasi} />
                        </Box>

                        <Box
                            sx={pilih_menu === "pindah saldo" ? { width: "100%" } : { display: "none" }}
                        >
                            <PindahSaldo aplikasi={props.aplikasi} />
                        </Box>

                        <Box
                            sx={pilih_menu === "pengaturan" ? { width: "100%" } : { display: "none" }}
                        >
                            <Pengaturan aplikasi={props.aplikasi} file={props.file} />
                        </Box>

                        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                            <Box
                                sx={{
                                    margin: "0 auto",
                                    display: "flex",
                                    flexDirection: "row",
                                    overflowX: "auto"
                                }}
                            >
                                <center>
                                    <BottomNavigation
                                        showLabels
                                        sx={{
                                            backgroundColor: "#35b2b8",
                                            width: "100vmax"
                                        }}
                                        value={pilih_menu}
                                        onChange={(event, newValue) => {
                                            if (newValue === "keluar") {
                                                setTampilDialogKeluar(true);
                                                return;
                                            }
                                            setPilihMenu(newValue);
                                        }}
                                    >
                                        <BottomNavigationAction
                                            value="beranda"
                                            label="Beranda"
                                            icon={<HomeIcon />}
                                            sx={{ color: "white" }}
                                        />
                                        <BottomNavigationAction
                                            value="penjualan"
                                            label="Penjualan"
                                            icon={<AttachMoneyIcon />}
                                            sx={{ color: "white" }}
                                        />                                        
                                        <BottomNavigationAction
                                            value="retur_penjualan"
                                            label="Retur Penjualan"
                                            icon={<ReplayIcon />}
                                            sx={{ color: "white" }}
                                        />   
                                        <BottomNavigationAction
                                            value="piutang"
                                            label="Piutang"
                                            icon={<PaymentsIcon />}
                                            sx={{ color: "white" }}
                                        />
                                        <BottomNavigationAction
                                            value="riwayat_transaksi"
                                            label="Riwayat Penjualan"
                                            icon={<HistoryIcon />}
                                            sx={{ color: "white" }}
                                        />
                                        <BottomNavigationAction
                                            value="riwayat_retur"
                                            label="Riwayat Retur"
                                            icon={<HistoryIcon />}
                                            sx={{ color: "white" }}
                                        />
                                        <BottomNavigationAction
                                            value="riwayat_akuntansi"
                                            label="Riwayat Transaksi"
                                            icon={<HistoryIcon />}
                                            sx={{ color: "white" }}
                                        />
                                        <BottomNavigationAction
                                            value="pindah saldo"
                                            label="Pindah Saldo"
                                            icon={<MoneyIcon />}
                                            sx={{ color: "white" }}
                                        />
                                        <BottomNavigationAction
                                            value="pengaturan"
                                            label="Pengaturan"
                                            icon={<SettingsIcon />}
                                            sx={{ color: "white" }}
                                        />
                                        <BottomNavigationAction
                                            value="keluar"
                                            label="Keluar"
                                            icon={<ExitToAppIcon />}
                                            sx={{ color: "white" }}
                                        />
                                    </BottomNavigation>
                                </center>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
            </div>
        );
    }
}

export default Utama;