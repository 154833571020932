import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

function Beranda(props, ref) {
    //state    
    const [saldo, setSaldo] = useState(0);
    const [saldo_non_tunai, setSaldoNonTunai] = useState(0);
    const [saldo_total, setSaldoTotal] = useState(0);
    const [data_terlaris_hari, setDataTerlarisHari] = useState([]);
    const [data_terlaris_bulan, setDataTerlarisBulan] = useState([]);
    const [data_habis, setDataHabis] = useState([]);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/kasir/tampil_beranda.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSaldo(data.data[0].saldo);
                    setSaldoNonTunai(data.data[0].saldo_non_tunai);
                    setSaldoTotal(data.data[0].saldo_total);
                    setDataTerlarisHari(data.data[0].terlaris_hari);
                    setDataTerlarisBulan(data.data[0].terlaris_bulan);
                    setDataHabis(data.data[0].habis);
                }
                else {
                    setSaldo(0);
                    setSaldoNonTunai(0);
                    setSaldoTotal(0);
                    setDataTerlarisHari([]);
                    setDataTerlarisBulan([]);
                    setDataHabis([]);
                }
            })
            .catch(error => {
                setSaldo(0);
                setSaldoNonTunai(0);
                setSaldoTotal(0);
                setDataTerlarisHari([]);
                setDataTerlarisBulan([]);
                setDataHabis([]);
            }
            );

        const auto_refresh = setInterval(() => {
            fetch(props.aplikasi + '/kasir/tampil_beranda.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            id_pengguna: localStorage.getItem("id_pengguna")
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        setSaldo(data.data[0].saldo);
                        setSaldoNonTunai(data.data[0].saldo_non_tunai);
                        setSaldoTotal(data.data[0].saldo_total);
                        setDataTerlarisHari(data.data[0].terlaris_hari);
                        setDataTerlarisBulan(data.data[0].terlaris_bulan);
                        setDataHabis(data.data[0].habis);
                    }
                    else {
                        setSaldo(0);
                        setSaldoNonTunai(0);
                        setSaldoTotal(0);
                        setDataTerlarisHari([]);
                        setDataTerlarisBulan([]);
                        setDataHabis([]);
                    }
                })
                .catch(error => {
                    setSaldo(0);
                    setSaldoNonTunai(0);
                    setSaldoTotal(0);
                    setDataTerlarisHari([]);
                    setDataTerlarisBulan([]);
                    setDataHabis([]);
                }
                );
        }, 10000);

        return () => {
            clearInterval(auto_refresh);
        };
    }, [props.aplikasi]);

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(2)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    const tampil_foto = () => {
        if (props.file + "/Foto/Aplikasi/logo.png" !== "null") {
            return props.file + "/Foto/Aplikasi/logo.png";
        }
    }

    return (
        <div>
            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    padding: "10px"
                }}
            >
                <Box sx={{ padding: "10px" }}>
                    <center>
                        {/* <Avatar
                            src={tampil_foto()}
                            sx={{ width: "20vmin", height: "20vmin" }}
                        /> */}

                        <img 
                            alt="foto-profil"
                            src={tampil_foto()}
                            style={{ height: "20vmin" }}
                        />
                    </center>
                    <br />
                    <center>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={6}>
                                <Typography variant="caption">
                                    Saldo Non Tunai
                                </Typography>
                                <Typography variant="h5" sx={{ color: "red" }}>
                                    Rp. {format_rupiah(parseFloat(saldo_non_tunai))}
                                </Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography variant="caption">
                                    Saldo Total
                                </Typography>
                                <Typography variant="h5" sx={{ color: "red" }}>
                                    Rp. {format_rupiah(parseFloat(saldo_total))}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Typography variant="body2">
                            Saldo Tunai Anda
                        </Typography>
                        <Typography variant="h3" sx={{ color: "red" }}>
                            Rp. {format_rupiah(parseFloat(saldo))}
                        </Typography>
                    </center>
                    <br />

                    <Grid container item spacing={1}>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <Card
                                    sx={{ padding: "10px", backgroundColor: "white" }}
                                >
                                    <Typography variant="h6">
                                        Produk Terlaris
                                    </Typography>
                                    <Divider />
                                    <br />

                                    <Grid
                                        container
                                    >
                                        <Grid
                                            item xs={5}
                                        >
                                            <Typography variant="h6"
                                                sx={{
                                                    padding: "5px",
                                                    backgroundColor: "#baa8e6",
                                                    color: "white"
                                                }}
                                            >
                                                Hari Ini
                                            </Typography>
                                            <Grid container item spacing={1}
                                                sx={{
                                                    padding: "5px"
                                                }}
                                            >
                                                {data_terlaris_hari.map((data_terlaris_makanan, index) => {
                                                    return (
                                                        <Grid key={index} container item xs={12}>
                                                            <Grid item xs={9}>
                                                                <Typography variant="body2">
                                                                    {data_terlaris_makanan.nama}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography variant="body2" align="right" sx={{ color: "green" }}>
                                                                    {format_rupiah(parseFloat(data_terlaris_makanan.jumlah))}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container item xs={1} justifyContent="center"
                                        >
                                            <Divider orientation='vertical' variant='middle' />
                                        </Grid>

                                        <Grid
                                            item xs={6}
                                        >
                                            <Typography variant="h6"
                                                sx={{
                                                    padding: "5px",
                                                    backgroundColor: "#baa8e6",
                                                    color: "white"
                                                }}
                                            >
                                                Bulan Ini
                                            </Typography>
                                            <Grid container item spacing={1}
                                                sx={{
                                                    padding: "5px"
                                                }}
                                            >
                                                {data_terlaris_bulan.map((data_terlaris_makanan, index) => {
                                                    return (
                                                        <Grid key={index} container item xs={12}>
                                                            <Grid item xs={9}>
                                                                <Typography variant="body2">
                                                                    {data_terlaris_makanan.nama}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography variant="body2" align="right" sx={{ color: "green" }}>
                                                                    {format_rupiah(parseFloat(data_terlaris_makanan.jumlah))}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Card sx={{ padding: "10px" }}>
                                    <Typography variant="h6">
                                        Stok Habis
                                    </Typography>
                                    <Divider />
                                    <br />
                                    <Grid container item spacing={1}>
                                        {data_habis.map((data_habis, index) => {
                                            return (
                                                <Grid key={index} item xs={12}>
                                                    <Typography variant="body2">
                                                        {data_habis.nama}
                                                    </Typography>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    );
}

export default forwardRef(Beranda);