import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

//gambar
import AddOutlined from '@mui/icons-material/AddOutlined';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import CachedIcon from '@mui/icons-material/Cached';

const Input = styled('input')({
    display: 'none',
});

function Pengguna(props, ref) {
    //state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [tampil_dialog, setTampilDialog] = useState(false);
    const [sumber_pengguna, setSumberPengguna] = useState([]);
    const [data_pengguna, setDataPengguna] = useState([]);
    const [id_pengguna, setIdPengguna] = useState("");
    const [nama, setNama] = useState("");
    const [username, setUsername] = useState("");
    const [level, setLevel] = useState("");
    const [data_gl, setDataGl] = useState([]);
    const [id_gl, setIdGl] = useState("");
    const [status, setStatus] = useState(false);
    const [label_status, setLabelStatus] = useState("Nonaktif");
    const [tampung_foto, setTampungFoto] = useState(null);
    const [foto, setFoto] = useState(null);
    const [jenis_file, setJenisFile] = useState("");
    const [nama_foto, setNamaFoto] = useState("");
    const [tampil_dialog_reset, setTampilDialogReset] = useState(false);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/pengguna/tampil_pengguna.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSumberPengguna(data.data);
                    setDataPengguna(data.data);
                }
                else {
                    setSumberPengguna([]);
                    setDataPengguna([]);
                }
            })
            .catch(error => {
                setSumberPengguna([]);
                setDataPengguna([]);
            }
            );

        fetch(props.aplikasi + '/pengguna/tampil_gl.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataGl(data.data);
                }
                else {
                    setDataGl([]);
                }
            })
            .catch(error => {
                setDataGl([]);
            }
            );
    }, [props.aplikasi]);

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const tampil_foto = (foto) => {
        if (foto !== "null") {
            return props.file + "/Foto/Pengguna/" + foto;
        }
    }

    const tampil_pengguna = () => {
        fetch(props.aplikasi + '/pengguna/tampil_pengguna.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSumberPengguna(data.data);
                    setDataPengguna(data.data);
                }
                else {
                    setSumberPengguna([]);
                    setDataPengguna([]);
                }
            })
            .catch(error => {
                setSumberPengguna([]);
                setDataPengguna([]);
            }
            );
    }

    const switch_level = (param) => {
        switch (param) {
            case "A":
                return "Admin";
            case "K":
                return "Kasir";
            default:
                break;
        }
    }

    const cari = (event) => {
        var cari = (event.target.value).toLowerCase();
        var awal = 1;
        var akhir = sumber_pengguna.length;

        var posisi = 0;
        var tampung = [];
        while (awal <= akhir) {
            var hasil = ((sumber_pengguna[awal - 1]["nama"]).toLowerCase()).indexOf(cari);
            if (hasil === -1) {
                hasil = ((sumber_pengguna[awal - 1]["nama"]).toLowerCase()).indexOf(cari);
            }

            if (hasil !== -1) {
                tampung[posisi] = sumber_pengguna[awal - 1];
                posisi++;
            }
            awal++;
        }

        setDataPengguna(tampung);
    }

    const bersih_input_pengguna = () => {
        setIdPengguna("");
        setNama("");
        setUsername("");
        setLevel("");
        setIdGl("");
        setStatus(false);
        setLabelStatus("Nonaktif");
        setTampungFoto(null);
        setFoto(null);
        setJenisFile("");
        setNamaFoto("");
    }

    const simpan = () => {
        if (nama === "") {
            setJenisNotif("warning");
            setIsiNotif("Nama wajib diisi");
            setTampilNotif(true);
            document.getElementById("nama").focus();
            return;
        }

        if (username.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Username wajib diisi");
            setTampilNotif(true);
            document.getElementById("username").focus();
            return;
        }

        if (level.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Level jabatan wajib diisi");
            setTampilNotif(true);
            document.getElementById("level").focus();
            return;
        }

        if (level.trim() === "K" && id_gl.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("General Ledger wajib diisi");
            setTampilNotif(true);
            document.getElementById("gl").focus();
            return;
        }

        setProses(true);
        if (id_pengguna === "") {
            fetch(props.aplikasi + '/pengguna/simpan_pengguna.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "simpan",
                            id_pengguna: "",
                            nama: nama,
                            username: username,
                            level: level,
                            id_gl: id_gl,
                            status: status,
                            foto: foto,
                            jenis_file: jenis_file
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        bersih_input_pengguna();
                        tampil_pengguna();
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                    else {
                        tampil_pengguna();
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                })
                .catch(error => {
                    tampil_pengguna();
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
                );
        }
        else {
            fetch(props.aplikasi + '/pengguna/simpan_pengguna.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "perbarui",
                            id_pengguna: id_pengguna,
                            nama: nama,
                            username: username,
                            level: level,
                            id_gl: id_gl,
                            status: status,
                            foto: foto,
                            jenis_file: jenis_file
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        tampil_pengguna();
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                    else {
                        tampil_pengguna();
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                })
                .catch(error => {
                    tampil_pengguna();
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
                );
        }
    }

    const reset_password = () => {
        setProses(true);
        fetch(props.aplikasi + '/pengguna/reset_password.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pengguna: id_pengguna
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    bersih_input_pengguna();
                    tampil_pengguna();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
                else {
                    tampil_pengguna();
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
            })
            .catch(error => {
                tampil_pengguna();
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                document.getElementById("nama").focus();
                setProses(false);
            }
            );
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Fab
                    color="primary"
                    sx={{
                        background: "#37ce9b",
                        border: "#37ce9b",
                        margin: 0,
                        top: "auto",
                        right: 20,
                        bottom: 20,
                        left: "auto",
                        position: "fixed",
                        zIndex: "99"
                    }}
                    onClick={() => {
                        bersih_input_pengguna();
                        setTampilDialog(true);
                    }}
                >
                    <AddOutlined />
                </Fab>

                <Card
                    sx={{ padding: "10px" }}
                >
                    <TextField
                        id="input-with-icon-textfield"
                        label="Cari berdasarkan nama pengguna"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchOutlined />
                                </InputAdornment>
                            ),
                        }}
                        onChange={cari}
                    />
                </Card>

                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    {data_pengguna.map((data_pengguna) => {
                        return (
                            <Grid
                                item xs={12} sm={6} md={4} lg={3}
                                key={data_pengguna.id_pengguna}
                                onClick={() => {
                                    bersih_input_pengguna();
                                    setIdPengguna(data_pengguna.id_pengguna);
                                    setNama(data_pengguna.nama);
                                    setUsername(data_pengguna.username);
                                    setLevel(data_pengguna.level);
                                    if (data_pengguna.status === "1") {
                                        setStatus(true);
                                        setLabelStatus("Aktif");
                                    }
                                    else {
                                        setStatus(false);
                                        setLabelStatus("Nonaktif");
                                    }
                                    setIdGl(data_pengguna.id_gl);
                                    setNamaFoto(data_pengguna.foto);
                                    setTampilDialog(true);
                                }}
                            >
                                <Card>
                                    <List>
                                        <ListItem button alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar
                                                    src={data_pengguna.foto !== null ? tampil_foto(data_pengguna.foto) : null}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography component={"span"} variant="body1" noWrap>{data_pengguna.nama}</Typography>
                                                }
                                                secondary={
                                                    <>
                                                        <Typography component={"span"} variant="subtitle1" noWrap>{data_pengguna.username}</Typography>
                                                        <br />
                                                        <Typography component={"span"} variant="body2" noWrap>{switch_level(data_pengguna.level)}</Typography>
                                                    </>
                                                }
                                            >
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>

            <Dialog
                open={tampil_dialog}
                onClose={() => setTampilDialog(false)}
            >
                <DialogTitle>Input Data Pengguna</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <center>
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                badgeContent={
                                    <label htmlFor="icon-button-file">
                                        <Input accept="image/*" id="icon-button-file" type="file"
                                            onChange={(e) => {
                                                setFoto(e.target.files[0]);
                                                setTampungFoto(e.target.files[0]);
                                                setJenisFile(e.target.files[0].name.split(".").pop());
                                                let reader = new FileReader();
                                                reader.readAsDataURL(e.target.files[0]);
                                                reader.onload = () => {
                                                    setFoto(reader.result);
                                                }
                                            }}
                                        />
                                        <Fab
                                            size="small"
                                            color="primary"
                                            component="span"
                                        >
                                            <PhotoCamera />
                                        </Fab>
                                    </label>
                                }
                            >
                                <Avatar
                                    src={tampung_foto !== null ? URL.createObjectURL(tampung_foto) : props.file + "/Foto/Pengguna/" + nama_foto}
                                    sx={{ width: "20vmin", height: "20vmin" }}
                                />
                            </Badge>
                        </center>
                        <br />
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography component={"span"} variant="body2">
                                        Nama Lengkap
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <TextField
                                        id="nama"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        multiline
                                        value={nama}
                                        onChange={(event) => setNama(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography component={"span"} variant="body2">
                                        Username
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <TextField
                                        id="username"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        multiline
                                        value={username}
                                        onChange={(event) => setUsername(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography component={"span"} variant="body2">
                                        Level Jabatan
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <FormControl size="small" fullWidth>
                                        <Select
                                            id="level"
                                            variant="outlined"
                                            fullWidth
                                            value={level}
                                            onChange={(event) => setLevel(event.target.value)}
                                        >
                                            <MenuItem value="A">Admin</MenuItem>
                                            <MenuItem value="K">Kasir</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container item
                                style={
                                    level === "K" ? {} : { display: "none" }
                                }
                            >
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography component={"span"} variant="body2">
                                        General Ledger
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <FormControl size="small" fullWidth>
                                        <Select
                                            id="gl"
                                            variant="outlined"
                                            fullWidth
                                            value={id_gl}
                                            onChange={(event) => setIdGl(event.target.value)}
                                        >
                                            {data_gl.map((data_gl) => {
                                                return (
                                                    <MenuItem key={data_gl.id_gl} value={data_gl.id_gl}>{data_gl.gl}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography component={"span"} variant="body2">
                                        Status
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={status}
                                                onChange={(event) => {
                                                    if (status === false) {
                                                        setStatus(true);
                                                        setLabelStatus("Aktif");
                                                    }
                                                    else {
                                                        setStatus(false);
                                                        setLabelStatus("Nonaktif");
                                                    }
                                                }}
                                            />
                                        }

                                        label={label_status}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                        loading={proses}
                        onClick={() => simpan()}
                    >
                        <Typography component={"span"} variant="body2" noWrap={true}>{id_pengguna === "" ? "Simpan" : "Perbarui"}</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CachedIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "tomato" }}
                        loading={proses}
                        onClick={() => setTampilDialogReset(true)}
                    >
                        <Typography component={"span"} variant="body2" noWrap={true}>Reset Password</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        loading={proses}
                        onClick={() => setTampilDialog(false)}
                    >
                        <Typography component={"span"} variant="body2" noWrap={true}>Tutup</Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_reset}
                onClose={() => setTampilDialogReset(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Reset Password
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin akan mereset password pengguna menjadi sama dengan nama akun?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loading={proses}
                        onClick={() => setTampilDialogReset(false)}
                        autoFocus
                    >
                        Tidak
                    </LoadingButton>
                    <LoadingButton
                        loading={proses}
                        onClick={() => {
                            setTampilDialogReset(false);
                            setTampilDialog(false);
                            reset_password();
                        }}
                    >
                        Ya
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default forwardRef(Pengguna);