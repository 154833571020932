import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Autocomplete from '@mui/material/Autocomplete';
import moment from 'moment';

//gambar
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

function Otorisasi(props, ref) {
    //state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [posisi_tab, setPosisiTab] = useState("UBAH METODE BAYAR");
    const [tampil_dialog, setTampilDialog] = useState(false);
    const [tampil_dialog_pembeli, setTampilDialogPembeli] = useState(false);
    const [tampil_dialog_pembatalan, setTampilDialogPembatalan] = useState(false);
    const [tampil_dialog_pembebasan_hutang, setTampilDialogPembebasanHutang] = useState(false);
    const [tampil_konfirmasi_pembebasan_hutang, setTampilKonfirmasiPembebasanHutang] = useState(false);
    const [tampil_dialog_hapus_belanja, setTampilDialogHapusBelanja] = useState(false);
    const [data_metode, setDataMetode] = useState([]);
    const [data_anggota, setDataAnggota] = useState([]);
    const [data_pembatalan, setDataPembatalan] = useState([]);
    const [data_pembebasan_hutang, setDataPembebasanHutang] = useState([]);
    const [data_transaksi_hutang, setDataTransaksiHutang] = useState([]);
    const [data_angsuran, setDataAngsuran] = useState([]);
    const [id_permintaan, setIdPermintaan] = useState("");
    const [kasir, setKasir] = useState("");
    const [pemesan, setPemesan] = useState("");
    const [grand_total, setGrandTotal] = useState("0");
    const [waktu_transaksi, setWaktuTransaksi] = useState("");
    const [metode_sekarang, setMetodeSekarang] = useState("");
    const [metode_baru, setMetodeBaru] = useState("");
    const [id_penjualan, setIdPenjualan] = useState("");
    const [nama_anggota, setNamaAnggota] = useState("");
    const [anggota_sekarang, setAnggotaSekarang] = useState("");
    const [anggota_baru, setAnggotaBaru] = useState("");
    const [hutang_awal, setHutangAwal] = useState(0);
    const [angsuran, setAngsuran] = useState(0);
    const [jumlah_pembebasan, setJumlahPembebasan] = useState(0);
    const [id_pembelian, setIdBelanja] = useState("");
    const [anggota, setAnggota] = useState(null);
    const [transaksi_hutang, setTransaksiHutang] = useState(null);
    const [dilunasi, setDilunasi] = useState(null);
    const [tampil_dialog_hapus, setTampilDialogHapus] = useState(false);
    const [tampil_dialog_hapus_angsuran, setTampilDialogHapusAngsuran] = useState(false);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/metode_bayar/tampil_permintaan_ubah.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataMetode(data.data);
                }
                else {
                    setDataMetode([]);
                }
            })
            .catch(error => {
                setDataMetode([]);
            }
            );
    }, [props.aplikasi]);

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(0)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const ubah_posisi = (nilai) => {
        setDataMetode([]);
        setDataAnggota([]);
        setDataPembatalan([]);
        setDataPembebasanHutang([]);

        switch (nilai) {
            case "UBAH METODE BAYAR":
                setPosisiTab("UBAH METODE BAYAR");

                fetch(props.aplikasi + '/metode_bayar/tampil_permintaan_ubah.php',
                    {
                        method: 'GET',
                        headers:
                        {
                            "Content-type": "application/json; charset=UTF-8"
                        }
                    })
                    .then(response => response.json())
                    .then(data => {
                        if (data.kode === 1) {
                            setDataMetode(data.data);
                        }
                        else {
                            setDataMetode([]);
                        }
                    })
                    .catch(error => {
                        setDataMetode([]);
                    }
                    );
                break;
            case "UBAH PEMBELI":
                setPosisiTab("UBAH PEMBELI");

                fetch(props.aplikasi + '/kasir/tampil_permintaan_ubah_pembeli.php',
                    {
                        method: 'GET',
                        headers:
                        {
                            "Content-type": "application/json; charset=UTF-8"
                        }
                    })
                    .then(response => response.json())
                    .then(data => {
                        if (data.kode === 1) {
                            setDataAnggota(data.data);
                        }
                        else {
                            setDataAnggota([]);
                        }
                    })
                    .catch(error => {
                        setDataAnggota([]);
                    }
                    );
                break;
            case "BATAL PENJUALAN":
                setPosisiTab("BATAL PENJUALAN");

                fetch(props.aplikasi + '/metode_bayar/tampil_permintaan_pembatalan.php',
                    {
                        method: 'GET',
                        headers:
                        {
                            "Content-type": "application/json; charset=UTF-8"
                        }
                    })
                    .then(response => response.json())
                    .then(data => {
                        if (data.kode === 1) {
                            setDataPembatalan(data.data);
                        }
                        else {
                            setDataPembatalan([]);
                        }
                    })
                    .catch(error => {
                        setDataPembatalan([]);
                    }
                    );
                break;
            case "BATAL PEMBELIAN":
                setPosisiTab("BATAL PEMBELIAN");
                break;
            case "BATAL PELUNASAN":
                setPosisiTab("BATAL PELUNASAN");

                fetch(props.aplikasi + '/karyawan/tampil_karyawan.php',
                    {
                        method: 'POST',
                        body: JSON.stringify
                            ({
                                status: "1"
                            }),
                        headers:
                        {
                            "Content-type": "application/json; charset=UTF-8"
                        }
                    })
                    .then(response => response.json())
                    .then(data => {
                        if (data.kode === 1) {
                            setDataAnggota(data.data);
                        }
                        else {
                            setDataAnggota([]);
                        }
                    })
                    .catch(error => {
                        setDataAnggota([]);
                    }
                    );
                break;
            case "PEMBEBASAN HUTANG":
                setPosisiTab("PEMBEBASAN HUTANG");
                fetch(props.aplikasi + '/kasir/tampil_piutang.php',
                    {
                        method: 'GET',
                        headers:
                        {
                            "Content-type": "application/json; charset=UTF-8"
                        }
                    })
                    .then(response => response.json())
                    .then(data => {
                        if (data.kode === 1) {
                            setDataPembebasanHutang(data.data);
                        }
                        else {
                            setDataPembebasanHutang([]);
                        }
                    })
                    .catch(error => {
                        setDataPembebasanHutang([]);
                    }
                    );
                break;
            default:
                break;
        }
    }

    const tampil_metode = () => {
        if (posisi_tab === "UBAH METODE BAYAR") {
            fetch(props.aplikasi + '/metode_bayar/tampil_permintaan_ubah.php',
                {
                    method: 'POST',
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        setDataMetode(data.data);
                    }
                    else {
                        setDataMetode([]);
                    }
                })
                .catch(error => {
                    setDataMetode([]);
                }
                );
        }
        else if (posisi_tab === "UBAH PEMBELI") {
            fetch(props.aplikasi + '/kasir/tampil_permintaan_ubah_pembeli.php',
                {
                    method: 'POST',
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        setDataAnggota(data.data);
                    }
                    else {
                        setDataAnggota([]);
                    }
                })
                .catch(error => {
                    setDataAnggota([]);
                }
                );
        }
        else if (posisi_tab === "BATAL PENJUALAN") {
            fetch(props.aplikasi + '/metode_bayar/tampil_permintaan_pembatalan.php',
                {
                    method: 'GET',
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        setDataPembatalan(data.data);
                    }
                    else {
                        setDataPembatalan([]);
                    }
                })
                .catch(error => {
                    setDataPembatalan([]);
                }
                );
        }
        else if (posisi_tab === "PEMBEBASAN HUTANG") {
            fetch(props.aplikasi + '/kasir/tampil_piutang.php',
                {
                    method: 'GET',
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        setDataPembebasanHutang(data.data);
                    }
                    else {
                        setDataPembebasanHutang([]);
                    }
                })
                .catch(error => {
                    setDataPembebasanHutang([]);
                }
                );
        }
    }

    const simpan = (otorisasi) => {
        setProses(true);
        fetch(props.aplikasi + '/metode_bayar/otorisasi_ubah.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_permintaan: id_permintaan,
                        otorisasi: otorisasi
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    tampil_metode();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setTampilDialog(false);
                    setProses(false);
                }
                else {
                    tampil_metode();
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                tampil_metode();
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setProses(false);
            }
            );
    }

    const simpan_pembeli = (otorisasi) => {
        setProses(true);
        fetch(props.aplikasi + '/kasir/otorisasi_ubah_pembeli.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_permintaan: id_permintaan,
                        otorisasi: otorisasi
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    tampil_metode();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setTampilDialogPembeli(false);
                    setProses(false);
                }
                else {
                    tampil_metode();
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                tampil_metode();
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setProses(false);
            }
            );
    }

    const simpan_pembatalan = (otorisasi) => {
        setProses(true);
        fetch(props.aplikasi + '/metode_bayar/otorisasi_pembatalan.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_permintaan: id_permintaan,
                        id_pengguna: localStorage.getItem("id_pengguna"),
                        otorisasi: otorisasi
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    tampil_metode();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setTampilDialogPembatalan(false);
                    setProses(false);
                }
                else {
                    tampil_metode();
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                tampil_metode();
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setProses(false);
            }
            );
    }

    const simpan_pembebasan = () => {
        setProses(true);
        fetch(props.aplikasi + '/metode_bayar/simpan_pembebasan_hutang.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_penjualan: id_penjualan,
                        jumlah_pembebasan: jumlah_pembebasan,
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    tampil_metode();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setTampilDialogPembebasanHutang(false);
                    setProses(false);
                }
                else {
                    tampil_metode();
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                tampil_metode();
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setProses(false);
            }
            );
    }

    const hapus_belanja = () => {
        setProses(true);
        fetch(props.aplikasi + '/metode_bayar/otorisasi_pembatalan_pembelian.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pembelian: id_pembelian,
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setIdBelanja("");
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setTampilDialogHapusBelanja(false);
                    setProses(false);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setProses(false);
            }
            );
    }

    const hapus_angsuran = () => {
        setProses(true);
        fetch(props.aplikasi + '/kasir/otorisasi_hapus_angsuran.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_angsuran: dilunasi.id_angsuran,
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setTransaksiHutang(null);
                    setDataAngsuran([]);
                    setDilunasi(null);
                    fetch(props.aplikasi + '/kasir/tampil_transaksi_karyawan_hutang.php',
                        {
                            method: 'POST',
                            body: JSON.stringify
                                ({
                                    id_karyawan: anggota.id_karyawan
                                }),
                            headers:
                            {
                                "Content-type": "application/json; charset=UTF-8"
                            }
                        })
                        .then(response => response.json())
                        .then(data => {
                            if (data.kode === 1) {
                                setDataTransaksiHutang(data.data);
                            }
                            else {
                                setDataTransaksiHutang([]);
                            }
                        })
                        .catch(error => {
                            setDataTransaksiHutang([]);
                        }
                        );
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setProses(false);
            }
            );
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                }}
            >
                <Grid container spacing={1} sx={{ padding: "10px" }}>

                    <Box sx={{ width: '100%' }}>
                        <Tabs
                            centered
                            textColor="secondary"
                            indicatorColor="secondary"
                            value={posisi_tab}
                            onChange={(event, nilai) => ubah_posisi(nilai)}
                        >
                            <Tab
                                value="UBAH METODE BAYAR"
                                label="UBAH METODE BAYAR"
                                wrapped
                            />
                            <Tab
                                value="UBAH PEMBELI"
                                label="UBAH PEMBELI"
                                wrapped
                            />
                            <Tab
                                value="BATAL PENJUALAN"
                                label="BATAL PENJUALAN"
                                wrapped
                            />
                            <Tab
                                value="BATAL PEMBELIAN"
                                label="BATAL PEMBELIAN"
                                wrapped
                            />
                            <Tab
                                value="BATAL PELUNASAN"
                                label="BATAL PELUNASAN"
                                wrapped
                            />
                            <Tab
                                value="PEMBEBASAN HUTANG"
                                label="PEMBEBASAN HUTANG"
                                wrapped
                            />
                        </Tabs>
                    </Box>

                    <Grid
                        item
                        xs={12}
                    >
                        <Box
                            sx={{
                                height: "48px",
                                padding: "5px",
                                backgroundColor: "#baa8e6",
                                color: "white"
                            }}
                        />
                    </Grid>

                    <Grid
                        item xs={12}
                        sx={
                            posisi_tab !== "UBAH METODE BAYAR" ?
                                { display: "none" } :
                                {}
                        }
                    >
                        <Grid
                            container spacing={1}
                        >
                            {data_metode.map((data_metode) => {
                                return (
                                    <Grid
                                        item xs={12} sm={6} md={4} lg={3}
                                        key={data_metode.id_permintaan}
                                        onClick={() => {
                                            setIdPermintaan(data_metode.id_permintaan);
                                            setIdPenjualan(data_metode.id_penjualan);
                                            setNamaAnggota(data_metode.nama_anggota + " (" + data_metode.bagian + ")");
                                            setKasir(data_metode.kasir);
                                            setGrandTotal(data_metode.grand_total);
                                            setWaktuTransaksi(data_metode.waktu_transaksi);
                                            setMetodeSekarang(data_metode.nama_metode_sekarang);
                                            setMetodeBaru(data_metode.nama_metode_baru);
                                            setTampilDialog(true);
                                        }}
                                    >
                                        <Card>
                                            <List>
                                                <ListItem button>
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="body1" noWrap>
                                                                {data_metode.nama_anggota !== "" ?
                                                                    data_metode.nama_anggota + " (" + data_metode.bagian + ")" :
                                                                    "Umum"
                                                                }
                                                            </Typography>
                                                        }
                                                        secondary={
                                                            <div>
                                                                <div>
                                                                    <Typography variant="subtitle2" noWrap style={{ display: "inline" }}>{data_metode.nama_metode_sekarang}</Typography>
                                                                    <SyncAltIcon style={{ verticalAlign: "middle" }} />
                                                                    <Typography variant="subtitle2" noWrap style={{ display: "inline" }}>{data_metode.nama_metode_baru}</Typography>
                                                                </div>
                                                                <Typography variant="subtitle2" noWrap>{data_metode.waktu_transaksi}</Typography>
                                                                <Typography variant="subtitle2" noWrap>Rp. {data_metode.grand_total}</Typography>
                                                            </div>
                                                        }
                                                    >
                                                    </ListItemText>
                                                </ListItem>
                                            </List>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>

                    <Grid
                        item xs={12}
                        sx={
                            posisi_tab !== "UBAH PEMBELI" ?
                                { display: "none" } :
                                {}
                        }
                    >
                        <Grid
                            container spacing={1}
                        >
                            {data_anggota.map((data_anggota) => {
                                return (
                                    <Grid
                                        item xs={12} sm={6} md={4} lg={3}
                                        key={data_metode.id_permintaan}
                                        onClick={() => {
                                            setIdPermintaan(data_anggota.id_permintaan);
                                            setIdPenjualan(data_anggota.id_penjualan);

                                            if (data_anggota.id_anggota_sekarang !== "0") {
                                                setAnggotaSekarang(data_anggota.nama_anggota_sekarang + " (" + data_anggota.bagian_sekarang + ")");
                                            }
                                            else {
                                                setAnggotaSekarang("Umum");
                                            }

                                            if (data_anggota.id_anggota_baru !== "0") {
                                                setAnggotaBaru(data_anggota.nama_anggota_baru + " (" + data_anggota.bagian_baru + ")");
                                            }
                                            else {
                                                setAnggotaBaru("Umum");
                                            }

                                            setKasir(data_anggota.kasir);
                                            setGrandTotal(data_anggota.grand_total);
                                            setWaktuTransaksi(data_anggota.waktu_transaksi);
                                            setTampilDialogPembeli(true);
                                        }}
                                    >
                                        <Card>
                                            <List>
                                                <ListItem button>
                                                    <ListItemText
                                                        secondary={
                                                            <div>
                                                                <div>
                                                                    <Typography variant="subtitle2" noWrap style={{ display: "inline" }}>
                                                                        {
                                                                            data_anggota.id_anggota_sekarang !== "0" ?
                                                                                data_anggota.nama_anggota_sekarang + " (" + data_anggota.bagian_sekarang + ")" :
                                                                                "Umum"
                                                                        }
                                                                    </Typography>
                                                                    <SyncAltIcon style={{ verticalAlign: "middle" }} />
                                                                    <Typography variant="subtitle2" noWrap style={{ display: "inline" }}>
                                                                        {
                                                                            data_anggota.id_anggota_baru !== "0" ?
                                                                                data_anggota.nama_anggota_baru + " (" + data_anggota.bagian_baru + ")" :
                                                                                "Umum"
                                                                        }
                                                                    </Typography>
                                                                </div>
                                                                <Typography variant="subtitle2" noWrap>{data_anggota.waktu_transaksi}</Typography>
                                                                <Typography variant="subtitle2" noWrap>Rp. {data_anggota.grand_total}</Typography>
                                                            </div>
                                                        }
                                                    >
                                                    </ListItemText>
                                                </ListItem>
                                            </List>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>

                    <Grid
                        item xs={12}
                        sx={
                            posisi_tab !== "BATAL PENJUALAN" ?
                                { display: "none" } :
                                {}
                        }
                    >
                        <Grid
                            container spacing={1}
                        >
                            {data_pembatalan.map((data_pembatalan) => {
                                return (
                                    <Grid
                                        item xs={12} sm={6} md={4} lg={3}
                                        key={data_pembatalan.id_permintaan}
                                        onClick={() => {
                                            setIdPermintaan(data_pembatalan.id_permintaan);
                                            setIdPenjualan(data_pembatalan.id_penjualan);
                                            setNamaAnggota(data_pembatalan.nama_anggota + " (" + data_pembatalan.bagian + ")");
                                            setKasir(data_pembatalan.kasir);
                                            setPemesan(data_pembatalan.pemesan);
                                            setGrandTotal(data_pembatalan.grand_total);
                                            setWaktuTransaksi(data_pembatalan.waktu_transaksi);
                                            setTampilDialogPembatalan(true);
                                        }}
                                    >
                                        <Card>
                                            <List>
                                                <ListItem button>
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="body1" noWrap>{data_pembatalan.nama_anggota + " (" + data_pembatalan.bagian + ")"}</Typography>
                                                        }
                                                        secondary={
                                                            <div>
                                                                <Typography variant="subtitle2" noWrap>{data_pembatalan.pemesan}</Typography>
                                                                <Typography variant="subtitle2" noWrap>{data_pembatalan.waktu_transaksi}</Typography>
                                                                <Typography variant="subtitle2" noWrap>Rp. {data_pembatalan.grand_total}</Typography>
                                                            </div>
                                                        }
                                                    >
                                                    </ListItemText>
                                                </ListItem>
                                            </List>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>

                    <Grid
                        item xs={12}
                        sx={
                            posisi_tab !== "BATAL PEMBELIAN" ?
                                { display: "none" } :
                                {}
                        }
                    >
                        <Grid container spacing={1}>
                            <Grid
                                item xs={12}
                            >
                                <Card sx={{ padding: "10px" }}>
                                    <Grid container item>
                                        <Grid item xs={4} md={3}>
                                            <Typography variant="body2">
                                                No. Bukti
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={4} md={3}>
                                            <TextField
                                                id="id_pembelian"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                value={id_pembelian}
                                                onChange={(e) => setIdBelanja(e.target.value)}
                                            />
                                        </Grid>

                                        <Grid item xs={4} md={3}>
                                            <LoadingButton
                                                loadingPosition="start"
                                                variant="contained"
                                                color="primary"
                                                startIcon={<DeleteIcon />}
                                                sx={{ marginLeft: "5px", borderRadius: "5em", backgroundColor: "red" }}
                                                loading={proses}
                                                onClick={() => {
                                                    if (id_pembelian === "") {
                                                        setJenisNotif("warning");
                                                        setIsiNotif("No. Bukti wajib diisi");
                                                        setTampilNotif(true);
                                                    }
                                                    else {
                                                        setTampilDialogHapusBelanja(true);
                                                    }
                                                }}
                                            >
                                                Hapus
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid
                        item xs={12}
                        sx={
                            posisi_tab !== "BATAL PELUNASAN" ?
                                { display: "none" } :
                                {}
                        }
                    >
                        <Grid container spacing={1}>
                            <Grid
                                item xs={12}
                            >
                                <Card sx={{ padding: "10px" }}>
                                    <Grid container item>
                                        <Typography variant="h6" noWrap={true}>1. Pilih Member</Typography>
                                    </Grid>

                                    <Grid container item>
                                        <Grid item xs={4} md={3}>
                                            <Typography variant="body2">
                                                Member
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={6} md={7}>
                                            <Autocomplete
                                                options={data_anggota}
                                                getOptionLabel={
                                                    (option) => option.nama + " (" + option.bagian + ")"
                                                }
                                                value={anggota}
                                                onChange={(e, nilai) => {
                                                    setAnggota(nilai);
                                                    setTransaksiHutang(null);
                                                    setDataAngsuran([]);
                                                    setDilunasi(null);
                                                    fetch(props.aplikasi + '/kasir/tampil_transaksi_karyawan_hutang.php',
                                                        {
                                                            method: 'POST',
                                                            body: JSON.stringify
                                                                ({
                                                                    id_karyawan: nilai.id_karyawan
                                                                }),
                                                            headers:
                                                            {
                                                                "Content-type": "application/json; charset=UTF-8"
                                                            }
                                                        })
                                                        .then(response => response.json())
                                                        .then(data => {
                                                            if (data.kode === 1) {
                                                                setDataTransaksiHutang(data.data);
                                                            }
                                                            else {
                                                                setDataTransaksiHutang([]);
                                                            }
                                                        })
                                                        .catch(error => {
                                                            setDataTransaksiHutang([]);
                                                        }
                                                        );
                                                }}
                                                renderInput={
                                                    (params) =>
                                                        <TextField
                                                            {...params}
                                                            key={params.id_karyawan}
                                                            variant="outlined"
                                                            size="small"
                                                        />
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>

                            <Grid
                                item xs={12}
                            >
                                <Card sx={{ padding: "10px" }}>
                                    <Grid container item>
                                        <Typography variant="h6" noWrap={true}>2. Pilih Transaksi Hutang</Typography>
                                    </Grid>

                                    <Grid container item>
                                        <Grid item xs={4} md={3}>
                                            <Typography variant="body2">
                                                Transaksi
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={6} md={7}>
                                            <Autocomplete
                                                options={data_transaksi_hutang}
                                                getOptionLabel={
                                                    (option) => moment(option.waktu_transaksi).format("DD-MM-YYYY HH:mm:ss") + " (Total : Rp. " + format_rupiah(parseFloat(option.grand_total)) + "; Diangsur : Rp. " + format_rupiah(parseFloat(option.angsuran)) + ")"
                                                }
                                                value={transaksi_hutang}
                                                onChange={(e, nilai) => {
                                                    setTransaksiHutang(nilai);
                                                    setDataAngsuran([]);
                                                    setDilunasi(null);
                                                    fetch(props.aplikasi + '/kasir/tampil_transaksi_karyawan_hutang_angsuran.php', {
                                                        method: 'POST',
                                                        body: JSON.stringify
                                                            ({
                                                                id_penjualan: nilai.id_penjualan
                                                            }),
                                                        headers:
                                                        {
                                                            "Content-type": "application/json; charset=UTF-8"
                                                        }
                                                    })
                                                        .then(response => response.json())
                                                        .then(data => {
                                                            if (data.kode === 1) {
                                                                setDataAngsuran(data.data);
                                                            }
                                                            else {
                                                                setDataAngsuran([]);
                                                            }
                                                        })
                                                        .catch(error => {
                                                            setDataAngsuran([]);
                                                        }
                                                        );
                                                }}
                                                renderInput={
                                                    (params) =>
                                                        <TextField
                                                            {...params}
                                                            key={params.id_penjualan}
                                                            variant="outlined"
                                                            size="small"
                                                        />
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>

                            <Grid
                                item xs={12}
                            >
                                <Card sx={{ padding: "10px" }}>
                                    <Grid container item>
                                        <Typography variant="h6" noWrap={true}>3. Pilih Angsuran / Pelunasan Yang Akan Dibatalkan</Typography>
                                    </Grid>

                                    <Grid container item>
                                        <Grid item xs={4} md={3}>
                                            <Typography variant="body2">
                                                Transaksi
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={6} md={7}>
                                            <Autocomplete
                                                options={data_angsuran}
                                                getOptionLabel={
                                                    (option) => moment(option.waktu_transaksi).format("DD-MM-YYYY HH:mm:ss") + " (Angsuran : Rp. " + format_rupiah(parseFloat(option.jumlah_bayar)) + ")"
                                                }
                                                value={dilunasi}
                                                onChange={(e, nilai) => setDilunasi(nilai)}
                                                renderInput={
                                                    (params) =>
                                                        <TextField
                                                            {...params}
                                                            key={params.id_angsuran}
                                                            variant="outlined"
                                                            size="small"
                                                        />
                                                }
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container item>
                                        <Grid item xs={4} md={3}>

                                        </Grid>

                                        <Grid item>
                                            <LoadingButton
                                                loadingPosition="start"
                                                variant="contained"
                                                color="primary"
                                                startIcon={<DeleteIcon />}
                                                sx={{ marginLeft: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "red" }}
                                                loading={proses}
                                                onClick={() => {
                                                    if (dilunasi === null) {
                                                        setJenisNotif("warning");
                                                        setIsiNotif("Angsuran / pelunasan belum dipilih");
                                                        setTampilNotif(true);
                                                    }
                                                    else {
                                                        setTampilDialogHapusAngsuran(true);
                                                    }
                                                }}
                                            >
                                                Batalkan
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid
                        item xs={12}
                        sx={
                            posisi_tab !== "PEMBEBASAN HUTANG" ?
                                { display: "none" } :
                                {}
                        }
                    >
                        <Grid container spacing={1}>
                            {data_pembebasan_hutang.map((data_pembebasan_hutang) => {
                                return (
                                    <Grid
                                        item xs={12} sm={6} md={4} lg={3}
                                        key={data_pembebasan_hutang.id_penjualan}
                                        onClick={() => {
                                            setIdPenjualan(data_pembebasan_hutang.id_penjualan);
                                            setPemesan(data_pembebasan_hutang.nama_anggota + " (" + data_pembebasan_hutang.bagian + ")");
                                            setHutangAwal(parseFloat(data_pembebasan_hutang.hutang_awal));
                                            setAngsuran(parseFloat(data_pembebasan_hutang.angsuran));
                                            setJumlahPembebasan(parseFloat(data_pembebasan_hutang.hutang_awal) - parseFloat(data_pembebasan_hutang.angsuran));
                                            setWaktuTransaksi(data_pembebasan_hutang.waktu_transaksi);
                                            setTampilDialogPembebasanHutang(true);
                                        }}
                                    >
                                        <Card>
                                            <List>
                                                <ListItem button>
                                                    <ListItemText
                                                        primary={<Typography noWrap variant="subtitle1">{data_pembebasan_hutang.nama_anggota + " (" + data_pembebasan_hutang.bagian + ")"}</Typography>}
                                                        secondary={
                                                            <div>
                                                                <Typography noWrap variant="body2">{data_pembebasan_hutang.waktu_transaksi}</Typography>
                                                                <Typography noWrap variant="body2">Rp. {format_rupiah(parseFloat(data_pembebasan_hutang.hutang_awal) - parseFloat(data_pembebasan_hutang.angsuran))}</Typography>
                                                            </div>
                                                        }
                                                    >
                                                    </ListItemText>
                                                </ListItem>
                                            </List>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <Dialog
                open={tampil_dialog}
                onClose={() => setTampilDialog(false)}
            >
                <DialogTitle>Otorisasi Permintaan Ubah Metode Bayar</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        No. Bukti
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {id_penjualan}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Pelanggan
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {nama_anggota !== "" ? nama_anggota : "Umum"}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Nama Kasir
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {kasir}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Jumlah Transaksi
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        Rp. {grand_total}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Waktu Transaksi
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {waktu_transaksi}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Metode Sekarang
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {metode_sekarang}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Metode Baru
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {metode_baru}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                        loading={proses}
                        onClick={() => simpan("SETUJU")}
                    >
                        <Typography variant="body2" noWrap={true}>Terima</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<DoDisturbIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#c18446" }}
                        loading={proses}
                        onClick={() => simpan("TOLAK")}
                    >
                        <Typography variant="body2" noWrap={true}>Tolak</Typography>
                    </LoadingButton>

                    {/* <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<DeleteIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "red" }}
                        loading={proses}
                        onClick={() => {
                            setTampilDialogHapus(true);
                        }}
                    >
                        <Typography variant="body2" noWrap={true}>Hapus</Typography>
                    </LoadingButton> */}

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        loading={proses}
                        onClick={() => setTampilDialog(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_pembeli}
                onClose={() => setTampilDialogPembatalan(false)}
            >
                <DialogTitle>Otorisasi Permintaan Ubah Pembeli</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        No. Bukti
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {id_penjualan}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Nama Kasir
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {kasir}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Jumlah Transaksi
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        Rp. {grand_total}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Waktu Transaksi
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {waktu_transaksi}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Pelanggan Sekarang
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {anggota_sekarang}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Pelanggan Baru
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {anggota_baru}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                        loading={proses}
                        onClick={() => simpan_pembeli("SETUJU")}
                    >
                        <Typography variant="body2" noWrap={true}>Terima</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<DoDisturbIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#c18446" }}
                        loading={proses}
                        onClick={() => simpan_pembeli("TOLAK")}
                    >
                        <Typography variant="body2" noWrap={true}>Tolak</Typography>
                    </LoadingButton>

                    {/* <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<DeleteIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "red" }}
                        loading={proses}
                        onClick={() => {
                            setTampilDialogHapus(true);
                        }}
                    >
                        <Typography variant="body2" noWrap={true}>Hapus</Typography>
                    </LoadingButton> */}

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        loading={proses}
                        onClick={() => setTampilDialogPembeli(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_hapus}
                onClose={() => setTampilDialogHapus(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Hapus Permintaan
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin menghapus data ini?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loading={proses}
                        onClick={() => setTampilDialogHapus(false)}
                        autoFocus
                    >
                        Tidak
                    </LoadingButton>
                    <LoadingButton
                        loading={proses}
                        onClick={() => {
                            simpan("HAPUS");
                            setTampilDialogHapus(false);
                            setTampilDialog(false);
                        }}
                    >
                        Ya
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_pembatalan}
                onClose={() => setTampilDialogPembatalan(false)}
            >
                <DialogTitle>Otorisasi Permintaan Pembatalan Transaksi</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        No. Bukti
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {id_penjualan}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Pelanggan
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {nama_anggota !== "" ? nama_anggota : "Umum"}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Nama Kasir
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {kasir}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Jumlah Transaksi
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        Rp. {grand_total}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Waktu Transaksi
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {waktu_transaksi}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                        loading={proses}
                        onClick={() => simpan_pembatalan("SETUJU")}
                    >
                        <Typography variant="body2" noWrap={true}>Terima</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<DoDisturbIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#c18446" }}
                        loading={proses}
                        onClick={() => simpan_pembatalan("TOLAK")}
                    >
                        <Typography variant="body2" noWrap={true}>Tolak</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        loading={proses}
                        onClick={() => setTampilDialogPembatalan(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_pembebasan_hutang}
                onClose={() => setTampilDialogPembebasanHutang(false)}
            >
                <DialogTitle>Otorisasi Pembebasan Hutang</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        No. Bukti
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {id_penjualan}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Pelanggan
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {pemesan}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Waktu Transaksi
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {waktu_transaksi}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Hutang Awal
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        Rp. {format_rupiah(hutang_awal)}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Sudah Diangsur
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        Rp. {format_rupiah(angsuran)}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Sisa Hutang
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        Rp. {format_rupiah(jumlah_pembebasan)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                        loading={proses}
                        onClick={() => setTampilKonfirmasiPembebasanHutang(true)}
                    >
                        <Typography variant="body2" noWrap={true}>Bebaskan</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        loading={proses}
                        onClick={() => setTampilDialogPembebasanHutang(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_hapus_belanja}
                onClose={() => setTampilDialogHapusBelanja(false)}
            >
                <DialogTitle>Otorisasi Pembatalan Belanja Bahan Baku</DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin menghapus data ini?
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<DeleteIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "red" }}
                        loading={proses}
                        onClick={() => hapus_belanja()}
                    >
                        <Typography variant="body2" noWrap={true}>Hapus</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        loading={proses}
                        onClick={() => setTampilDialogHapusBelanja(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_konfirmasi_pembebasan_hutang}
                onClose={() => setTampilKonfirmasiPembebasanHutang(false)}
            >
                <DialogTitle>Konfirmasi</DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin membebaskan hutang pelanggan pada penjualan ini?
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        loading={proses}
                        onClick={() => setTampilKonfirmasiPembebasanHutang(false)}
                        autoFocus
                    >
                        Tidak
                    </LoadingButton>
                    <LoadingButton
                        loading={proses}
                        onClick={() => {
                            simpan_pembebasan();
                            setTampilKonfirmasiPembebasanHutang(false);
                            setTampilDialogPembebasanHutang(false);
                        }}
                    >
                        Ya
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_hapus_angsuran}
                onClose={() => setTampilDialogHapusAngsuran(false)}
            >
                <DialogTitle>
                    Konfirmasi Pembatalan Angsuran / Pelunasan
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Apakah anda yakin ingin membatalkan transaksi ini?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loading={proses}
                        onClick={() => setTampilDialogHapusAngsuran(false)}
                        autoFocus
                    >
                        Tidak
                    </LoadingButton>
                    <LoadingButton
                        loading={proses}
                        onClick={() => {
                            hapus_angsuran();
                            setTampilDialogHapusAngsuran(false);
                        }}
                    >
                        Ya
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default forwardRef(Otorisasi);