import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

//gambar
import PrintIcon from '@mui/icons-material/Print';
import BorderAllIcon from '@mui/icons-material/BorderAll';

function Lap_Produk(props, ref) {
    //state
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [periode1, setPeriode1] = useState(new Date());
    const [periode2, setPeriode2] = useState(new Date());
    const [data_produk, setDataProduk] = useState([]);
    const [produk, setProduk] = useState(null);
    const [input_produk, setInputProduk] = useState("");

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/produk/tampil_produk.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: "1"
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataProduk(data.data);
                }
                else {
                    setDataProduk([]);
                }
            })
            .catch(error => {
                setDataProduk([]);
            }
            );
    }, [props.aplikasi]);

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const cetak = () => {
        window.open(props.aplikasi + '/laporan/cetak_stok.php');
    }

    const excel = () => {
        window.open(props.aplikasi + '/laporan/excel_stok.php');
    }

    const cetak_mutasi = () => {
        if (periode1 === null) {
            setJenisNotif("warning");
            setIsiNotif("Periode awal wajib diisi");
            setTampilNotif(true);
            return;
        }

        if (periode2 === null) {
            setJenisNotif("warning");
            setIsiNotif("Periode akhir wajib diisi");
            setTampilNotif(true);
            return;
        }

        if (produk === null) {
            setJenisNotif("warning");
            setIsiNotif("Produk wajib dipilih");
            setTampilNotif(true);
            return;
        }

        var tahun = String(periode1.getFullYear());
        var bulan = String(periode1.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        var tanggal = String(periode1.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode1 = tahun + "-" + bulan + "-" + tanggal;

        tahun = String(periode2.getFullYear());
        bulan = String(periode2.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        tanggal = String(periode2.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode2 = tahun + "-" + bulan + "-" + tanggal;

        window.open(props.aplikasi + '/laporan/cetak_produk_mutasi.php?periode1=' + format_periode1 + '&periode2=' + format_periode2 + '&id_produk=' + produk.id_produk);
    }

    const excel_mutasi = () => {
        if (periode1 === null) {
            setJenisNotif("warning");
            setIsiNotif("Periode awal wajib diisi");
            setTampilNotif(true);
            return;
        }

        if (periode2 === null) {
            setJenisNotif("warning");
            setIsiNotif("Periode akhir wajib diisi");
            setTampilNotif(true);
            return;
        }

        if (produk === null) {
            setJenisNotif("warning");
            setIsiNotif("Produk wajib dipilih");
            setTampilNotif(true);
            return;
        }

        var tahun = String(periode1.getFullYear());
        var bulan = String(periode1.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        var tanggal = String(periode1.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode1 = tahun + "-" + bulan + "-" + tanggal;

        tahun = String(periode2.getFullYear());
        bulan = String(periode2.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        tanggal = String(periode2.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode2 = tahun + "-" + bulan + "-" + tanggal;

        window.open(props.aplikasi + '/laporan/excel_produk_mutasi.php?periode1=' + format_periode1 + '&periode2=' + format_periode2 + '&id_produk=' + produk.id_produk);
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Card
                    sx={{ margin: "10px", padding: "10px" }}
                >
                    <Grid container spacing={1}>
                        <Grid container item>
                            <Typography variant="h6" noWrap={true}>Stok Seluruh Produk</Typography>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3} >

                            </Grid>

                            <Grid item xs={8} sm={8} md={9} sx={{ display: "flex" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                    onClick={() => cetak()}
                                >
                                    <Typography variant="body2" noWrap={true}>Cetak</Typography>
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<BorderAllIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "green" }}
                                    onClick={() => excel()}
                                >
                                    <Typography variant="body2" noWrap={true}>Excel</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>

                <Card
                    sx={{ margin: "10px", padding: "10px" }}
                >
                    <Grid container spacing={1}>
                        <Grid container item>
                            <Typography variant="h6" noWrap={true}>Mutasi Produk</Typography>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3} >
                                <Typography variant="body2">
                                    Tanggal Transaksi
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9} sx={{ display: "flex" }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        inputFormat="dd-MM-yyyy"
                                        value={periode1}
                                        onChange={(newValue) => {
                                            setPeriode1(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>

                                <Typography variant="body2" sx={{ marginLeft: "10px", marginRight: "10px" }}>
                                    s/d
                                </Typography>

                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        inputFormat="dd-MM-yyyy"
                                        value={periode2}
                                        onChange={(newValue) => {
                                            setPeriode2(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3} >
                                <Typography variant="body2">
                                    Produk
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9} sx={{ display: "flex" }}>
                                <Autocomplete
                                    fullWidth
                                    options={data_produk}
                                    getOptionLabel={
                                        (option) => option.nama
                                    }
                                    inputValue={input_produk}
                                    value={produk}
                                    onInputChange={(e, nilai) => setInputProduk(nilai)}
                                    onChange={(e, nilai) => setProduk(nilai)}
                                    renderInput={
                                        (params) =>
                                            <TextField
                                                {...params}
                                                key={params.id_produk}
                                                variant="outlined"
                                                size="small"
                                            />
                                    }
                                />
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3} >

                            </Grid>

                            <Grid item xs={8} sm={8} md={9} sx={{ display: "flex" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                    onClick={() => cetak_mutasi()}
                                >
                                    <Typography variant="body2" noWrap={true}>Cetak</Typography>
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<BorderAllIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "green" }}
                                    onClick={() => excel_mutasi()}
                                >
                                    <Typography variant="body2" noWrap={true}>Excel</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Box>
        </div>
    );
}

export default forwardRef(Lap_Produk);