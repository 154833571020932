//utama
import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, Navigate } from 'react-router-dom';

//komponen
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

//form
import Beranda from './beranda';
import LapPenjualan from '../Laporan/penjualan';
import LapPembelian from '../Laporan/pembelian';
import LapReturPenjualan from '../Laporan/retur_penjualan';
import LapReturPembelian from '../Laporan/retur_pembelian';
import LapPenyesuaianStok from '../Laporan/penyesuaian_stok';
import LapHutangPiutang from '../Laporan/hutang_piutang';
import LapProduk from '../Laporan/produk';
import LapStok from '../Laporan/stok';
import LapSupplier from '../Laporan/supplier';
import LapMetodeBayar from '../Laporan/metode_bayar';
import LapKaryawan from '../Laporan/karyawan';
import Jurnal from '../Akuntansi/jurnal';
import Favorit from '../Akuntansi/favorit';
import GeneralLedger from '../Akuntansi/general_ledger';
import DaftarTransaksi from '../Akuntansi/daftar_transaksi';
import LapKeuangan from '../Akuntansi/laporan_keuangan';
import TrackingGL from '../Akuntansi/tracking_gl';
import Pembelian from './pembelian';
import PenyesuaianStok from './penyesuaian_stok';
import HutangSupplier from './hutang_supplier';
import ReturPembelian from './retur_pembelian';
import JenisProduk from './jenis_produk';
import Produk from './produk';
import Pengguna from './pengguna';
import Supplier from './supplier';
import MetodeBayar from './metode_bayar';
import Karyawan from './karyawan';
import PindahSaldo from './pindah_saldo';
import Otorisasi from './otorisasi';
import OpenClose from './open_close';
import Eom from './eom';
import Pengaturan from './pengaturan';

//gambar
import MenuIcon from '@mui/icons-material/Menu';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import FastfoodOutlinedIcon from '@mui/icons-material/FastfoodOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import MoneyIcon from '@mui/icons-material/Money';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import AddTaskIcon from '@mui/icons-material/AddTask';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

function Utama(props) {

  //state
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [menu, setMenu] = useState("Beranda");
  const [submenu_laporan, setSubmenuLaporan] = useState(false);
  const [submenu_akuntansi, setSubmenuAkuntansi] = useState(false);
  const [submenu_transaksi, setSubmenuTransaksi] = useState(false);
  const [submenu_master, setSubmenuMaster] = useState(false);
  const [tampil_dialog_keluar, setTampilDialogKeluar] = useState(false);

  //fungsi
  useEffect(() => {
    setMenu(localStorage.getItem("menu"));

    if (localStorage.getItem("submenu_laporan") === "true") {
      setSubmenuLaporan(true);
    }
    else {
      setSubmenuLaporan(false);
    }

    if (localStorage.getItem("submenu_akuntansi") === "true") {
      setSubmenuAkuntansi(true);
    }
    else {
      setSubmenuAkuntansi(false);
    }

    if (localStorage.getItem("submenu_transaksi") === "true") {
      setSubmenuTransaksi(true);
    }
    else {
      setSubmenuTransaksi(false);
    }

    if (localStorage.getItem("submenu_master") === "true") {
      setSubmenuMaster(true);
    }
    else {
      setSubmenuMaster(false);
    }
  }, [props.aplikasi]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const drawer = (
    <div>
      {/* <Toolbar />
      <Divider /> */}
      <List>
        <Link to="/beranda" style={{ textDecoration: "none", color: "black" }}
          onClick={() => {
            localStorage.setItem("menu", "Beranda");
            localStorage.setItem("submenu_laporan", false);
            localStorage.setItem("submenu_akuntansi", false);
            localStorage.setItem("submenu_transaksi", false);
            localStorage.setItem("submenu_master", false);
            setMenu("Beranda");
            setSubmenuLaporan(false);
            setSubmenuAkuntansi(false);
            setSubmenuTransaksi(false);
            setSubmenuMaster(false);
          }}
        >
          <ListItem selected={menu === "Beranda"}>
            <ListItemIcon><HomeOutlinedIcon style={{ color: "#35b2b8" }} /></ListItemIcon>
            <ListItemText>Beranda</ListItemText>
          </ListItem>
        </Link>

        <ListItem onClick={() => setSubmenuLaporan(!submenu_laporan)}>
          <ListItemIcon><DescriptionOutlinedIcon style={{ color: "blue" }} /></ListItemIcon>
          <ListItemText>Laporan</ListItemText>
          {submenu_laporan === true ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={submenu_laporan} timeout="auto" unmountOnExit>
          <Link to="/laporan_penjualan" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              localStorage.setItem("menu", "Laporan Penjualan");
              localStorage.setItem("submenu_laporan", true);
              localStorage.setItem("submenu_akuntansi", false);
              localStorage.setItem("submenu_transaksi", false);
              localStorage.setItem("submenu_master", false);
              setMenu("Laporan Penjualan");
              setSubmenuLaporan(true);
              setSubmenuAkuntansi(false);
              setSubmenuTransaksi(false);
              setSubmenuMaster(false);
            }}
          >
            <ListItem selected={menu === "Laporan Penjualan"} sx={{ pl: 9 }}>
              <ListItemText>Penjualan</ListItemText>
            </ListItem>
          </Link>

          <Link to="/laporan_pembelian" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              localStorage.setItem("menu", "Laporan Pembelian");
              localStorage.setItem("submenu_laporan", true);
              localStorage.setItem("submenu_akuntansi", false);
              localStorage.setItem("submenu_transaksi", false);
              localStorage.setItem("submenu_master", false);
              setMenu("Laporan Pembelian");
              setSubmenuLaporan(true);
              setSubmenuAkuntansi(false);
              setSubmenuTransaksi(false);
              setSubmenuMaster(false);
            }}
          >
            <ListItem selected={menu === "Laporan Pembelian"} sx={{ pl: 9 }}>
              <ListItemText>Pembelian</ListItemText>
            </ListItem>
          </Link>

          <Link to="/laporan_retur_penjualan" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              localStorage.setItem("menu", "Laporan Penjualan");
              localStorage.setItem("submenu_laporan", true);
              localStorage.setItem("submenu_akuntansi", false);
              localStorage.setItem("submenu_transaksi", false);
              localStorage.setItem("submenu_master", false);
              setMenu("Laporan Retur Penjualan");
              setSubmenuLaporan(true);
              setSubmenuAkuntansi(false);
              setSubmenuTransaksi(false);
              setSubmenuMaster(false);
            }}
          >
            <ListItem selected={menu === "Laporan Retur Penjualan"} sx={{ pl: 9 }}>
              <ListItemText>Retur Penjualan</ListItemText>
            </ListItem>
          </Link>

          <Link to="/laporan_retur_pembelian" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              localStorage.setItem("menu", "Laporan Retur Pembelian");
              localStorage.setItem("submenu_laporan", true);
              localStorage.setItem("submenu_akuntansi", false);
              localStorage.setItem("submenu_transaksi", false);
              localStorage.setItem("submenu_master", false);
              setMenu("Laporan Retur Pembelian");
              setSubmenuLaporan(true);
              setSubmenuAkuntansi(false);
              setSubmenuTransaksi(false);
              setSubmenuMaster(false);
            }}
          >
            <ListItem selected={menu === "Laporan Retur Pembelian"} sx={{ pl: 9 }}>
              <ListItemText>Retur Pembelian</ListItemText>
            </ListItem>
          </Link>

          <Link to="/laporan_penyesuaian_stok" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              localStorage.setItem("menu", "Laporan Penyesuaian Stok");
              localStorage.setItem("submenu_laporan", true);
              localStorage.setItem("submenu_akuntansi", false);
              localStorage.setItem("submenu_transaksi", false);
              localStorage.setItem("submenu_master", false);
              setMenu("Laporan Penyesuaian Stok");
              setSubmenuLaporan(true);
              setSubmenuAkuntansi(false);
              setSubmenuTransaksi(false);
              setSubmenuMaster(false);
            }}
          >
            <ListItem selected={menu === "Laporan Penyesuaian Stok"} sx={{ pl: 9 }}>
              <ListItemText>Penyesuaian Stok</ListItemText>
            </ListItem>
          </Link>

          <Link to="/laporan_hutang_piutang" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              localStorage.setItem("menu", "Laporan Hutang Piutang");
              localStorage.setItem("submenu_laporan", true);
              localStorage.setItem("submenu_akuntansi", false);
              localStorage.setItem("submenu_transaksi", false);
              localStorage.setItem("submenu_master", false);
              setMenu("Laporan Hutang Piutang");
              setSubmenuLaporan(true);
              setSubmenuAkuntansi(false);
              setSubmenuTransaksi(false);
              setSubmenuMaster(false);
            }}
          >
            <ListItem selected={menu === "Laporan Hutang Piutang"} sx={{ pl: 9 }}>
              <ListItemText>Hutang Piutang</ListItemText>
            </ListItem>
          </Link>

          <Link to="/laporan_produk" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              localStorage.setItem("menu", "Laporan Peringkat Produk");
              localStorage.setItem("submenu_laporan", true);
              localStorage.setItem("submenu_akuntansi", false);
              localStorage.setItem("submenu_transaksi", false);
              localStorage.setItem("submenu_master", false);
              setMenu("Laporan Peringkat Produk");
              setSubmenuLaporan(true);
              setSubmenuAkuntansi(false);
              setSubmenuTransaksi(false);
              setSubmenuMaster(false);
            }}
          >
            <ListItem selected={menu === "Laporan Peringkat Produk"} sx={{ pl: 9 }}>
              <ListItemText>Peringkat Produk</ListItemText>
            </ListItem>
          </Link>

          <Link to="/laporan_stok" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              localStorage.setItem("menu", "Laporan Stok Produk");
              localStorage.setItem("submenu_laporan", true);
              localStorage.setItem("submenu_akuntansi", false);
              localStorage.setItem("submenu_transaksi", false);
              localStorage.setItem("submenu_master", false);
              setMenu("Laporan Stok Produk");
              setSubmenuLaporan(true);
              setSubmenuAkuntansi(false);
              setSubmenuTransaksi(false);
              setSubmenuMaster(false);
            }}
          >
            <ListItem selected={menu === "Laporan Stok Produk"} sx={{ pl: 9 }}>
              <ListItemText>Stok Produk</ListItemText>
            </ListItem>
          </Link>

          <Link to="/laporan_supplier" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              localStorage.setItem("menu", "Laporan Supplier");
              localStorage.setItem("submenu_laporan", true);
              localStorage.setItem("submenu_akuntansi", false);
              localStorage.setItem("submenu_transaksi", false);
              localStorage.setItem("submenu_master", false);
              setMenu("Laporan Supplier");
              setSubmenuLaporan(true);
              setSubmenuAkuntansi(false);
              setSubmenuTransaksi(false);
              setSubmenuMaster(false);
            }}
          >
            <ListItem selected={menu === "Laporan Supplier"} sx={{ pl: 9 }}>
              <ListItemText>Supplier</ListItemText>
            </ListItem>
          </Link>

          <Link to="/laporan_metode_bayar" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              localStorage.setItem("menu", "Laporan Metode Bayar");
              localStorage.setItem("submenu_laporan", true);
              localStorage.setItem("submenu_akuntansi", false);
              localStorage.setItem("submenu_transaksi", false);
              localStorage.setItem("submenu_master", false);
              setMenu("Laporan Metode Bayar");
              setSubmenuLaporan(true);
              setSubmenuAkuntansi(false);
              setSubmenuTransaksi(false);
              setSubmenuMaster(false);
            }}
          >
            <ListItem selected={menu === "Laporan Metode Bayar"} sx={{ pl: 9 }}>
              <ListItemText>Metode Bayar</ListItemText>
            </ListItem>
          </Link>

          <Link to="/laporan_karyawan" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              localStorage.setItem("menu", "Laporan Karyawan");
              localStorage.setItem("submenu_laporan", true);
              localStorage.setItem("submenu_akuntansi", false);
              localStorage.setItem("submenu_transaksi", false);
              localStorage.setItem("submenu_master", false);
              setMenu("Laporan Karyawan");
              setSubmenuLaporan(true);
              setSubmenuAkuntansi(false);
              setSubmenuTransaksi(false);
              setSubmenuMaster(false);
            }}
          >
            <ListItem selected={menu === "Laporan Karyawan"} sx={{ pl: 9 }}>
              <ListItemText>Karyawan</ListItemText>
            </ListItem>
          </Link>
        </Collapse>

        <ListItem onClick={() => setSubmenuAkuntansi(!submenu_akuntansi)}>
          <ListItemIcon><AccountBalanceWalletIcon style={{ color: "green" }} /></ListItemIcon>
          <ListItemText>Akuntansi</ListItemText>
          {submenu_akuntansi === true ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={submenu_akuntansi} timeout="auto" unmountOnExit>
          <Link to="/jurnal" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              localStorage.setItem("menu", "Input Jurnal");
              localStorage.setItem("submenu_laporan", false);
              localStorage.setItem("submenu_akuntansi", true);
              localStorage.setItem("submenu_transaksi", false);
              localStorage.setItem("submenu_master", false);
              setMenu("Input Jurnal");
              setSubmenuLaporan(false);
              setSubmenuAkuntansi(true);
              setSubmenuTransaksi(false);
              setSubmenuMaster(false);
            }}
          >
            <ListItem selected={menu === "Input Jurnal"} sx={{ pl: 9 }}>
              <ListItemText>Input Jurnal</ListItemText>
            </ListItem>
          </Link>

          <Link to="/favorit" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              localStorage.setItem("menu", "Jurnal Favorit");
              localStorage.setItem("submenu_laporan", false);
              localStorage.setItem("submenu_akuntansi", true);
              localStorage.setItem("submenu_transaksi", false);
              localStorage.setItem("submenu_master", false);
              setMenu("Jurnal Favorit");
              setSubmenuLaporan(false);
              setSubmenuAkuntansi(true);
              setSubmenuTransaksi(false);
              setSubmenuMaster(false);
            }}
          >
            <ListItem selected={menu === "Jurnal Favorit"} sx={{ pl: 9 }}>
              <ListItemText>Jurnal Favorit</ListItemText>
            </ListItem>
          </Link>

          <Link to="/general_ledger" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              localStorage.setItem("menu", "General Ledger");
              localStorage.setItem("submenu_laporan", false);
              localStorage.setItem("submenu_akuntansi", true);
              localStorage.setItem("submenu_transaksi", false);
              localStorage.setItem("submenu_master", false);
              setMenu("General Ledger");
              setSubmenuLaporan(false);
              setSubmenuAkuntansi(true);
              setSubmenuTransaksi(false);
              setSubmenuMaster(false);
            }}
          >
            <ListItem selected={menu === "General Ledger"} sx={{ pl: 9 }}>
              <ListItemText>General Ledger</ListItemText>
            </ListItem>
          </Link>

          <Link to="/daftar_transaksi" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              localStorage.setItem("menu", "Daftar Transaksi");
              localStorage.setItem("submenu_laporan", false);
              localStorage.setItem("submenu_akuntansi", true);
              localStorage.setItem("submenu_transaksi", false);
              localStorage.setItem("submenu_master", false);
              setMenu("Daftar Transaksi");
              setSubmenuLaporan(false);
              setSubmenuAkuntansi(true);
              setSubmenuTransaksi(false);
              setSubmenuMaster(false);
            }}
          >
            <ListItem selected={menu === "Daftar Transaksi"} sx={{ pl: 9 }}>
              <ListItemText>Daftar Transaksi</ListItemText>
            </ListItem>
          </Link>

          <Link to="/laporan_keuangan" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              localStorage.setItem("menu", "Laporan Keuangan");
              localStorage.setItem("submenu_laporan", false);
              localStorage.setItem("submenu_akuntansi", true);
              localStorage.setItem("submenu_transaksi", false);
              localStorage.setItem("submenu_master", false);
              setMenu("Laporan Keuangan");
              setSubmenuLaporan(false);
              setSubmenuAkuntansi(true);
              setSubmenuTransaksi(false);
              setSubmenuMaster(false);
            }}
          >
            <ListItem selected={menu === "Laporan Keuangan"} sx={{ pl: 9 }}>
              <ListItemText>Lap. Keuangan</ListItemText>
            </ListItem>
          </Link>

          <Link to="/tracking_gl" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              localStorage.setItem("menu", "Tracking GL");
              localStorage.setItem("submenu_laporan", false);
              localStorage.setItem("submenu_akuntansi", true);
              localStorage.setItem("submenu_transaksi", false);
              localStorage.setItem("submenu_master", false);
              setMenu("Tracking GL");
              setSubmenuLaporan(false);
              setSubmenuAkuntansi(true);
              setSubmenuTransaksi(false);
              setSubmenuMaster(false);
            }}
          >
            <ListItem selected={menu === "Tracking GL"} sx={{ pl: 9 }}>
              <ListItemText>Tracking GL</ListItemText>
            </ListItem>
          </Link>
        </Collapse>

        <ListItem onClick={() => setSubmenuTransaksi(!submenu_transaksi)}>
          <ListItemIcon><ShoppingBasketIcon style={{ color: "#baa8e6" }} /></ListItemIcon>
          <ListItemText>Transaksi</ListItemText>
          {submenu_transaksi === true ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={submenu_transaksi} timeout="auto" unmountOnExit>
          <Link to="/pembelian" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              localStorage.setItem("menu", "Pembelian");
              localStorage.setItem("submenu_laporan", false);
              localStorage.setItem("submenu_akuntansi", false);
              localStorage.setItem("submenu_transaksi", true);
              localStorage.setItem("submenu_master", false);
              setMenu("Pembelian");
              setSubmenuLaporan(false);
              setSubmenuAkuntansi(false);
              setSubmenuTransaksi(true);
              setSubmenuMaster(false);
            }}
          >
            <ListItem selected={menu === "Pembelian"} sx={{ pl: 9 }}>
              <ListItemText>Pembelian</ListItemText>
            </ListItem>
          </Link>

          <Link to="/penyesuaian_stok" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              localStorage.setItem("menu", "Penyesuaian Stok");
              localStorage.setItem("submenu_laporan", false);
              localStorage.setItem("submenu_akuntansi", false);
              localStorage.setItem("submenu_transaksi", true);
              localStorage.setItem("submenu_master", false);
              setMenu("Penyesuaian Stok");
              setSubmenuLaporan(false);
              setSubmenuAkuntansi(false);
              setSubmenuTransaksi(true);
              setSubmenuMaster(false);
            }}
          >
            <ListItem selected={menu === "Penyesuaian Stok"} sx={{ pl: 9 }}>
              <ListItemText>Penyesuaian Stok</ListItemText>
            </ListItem>
          </Link>

          <Link to="/hutang_supplier" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              localStorage.setItem("menu", "Hutang Supplier");
              localStorage.setItem("submenu_laporan", false);
              localStorage.setItem("submenu_akuntansi", false);
              localStorage.setItem("submenu_transaksi", true);
              localStorage.setItem("submenu_master", false);
              setMenu("Hutang Supplier");
              setSubmenuLaporan(false);
              setSubmenuAkuntansi(false);
              setSubmenuTransaksi(true);
              setSubmenuMaster(false);
            }}
          >
            <ListItem selected={menu === "Hutang Supplier"} sx={{ pl: 9 }}>
              <ListItemText>Hutang Supplier</ListItemText>
            </ListItem>
          </Link>

          <Link to="/retur_pembelian" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              localStorage.setItem("menu", "Retur Pembelian");
              localStorage.setItem("submenu_laporan", false);
              localStorage.setItem("submenu_akuntansi", false);
              localStorage.setItem("submenu_transaksi", true);
              localStorage.setItem("submenu_master", false);
              setMenu("Retur Pembelian");
              setSubmenuLaporan(false);
              setSubmenuAkuntansi(false);
              setSubmenuTransaksi(true);
              setSubmenuMaster(false);
            }}
          >
            <ListItem selected={menu === "Retur Pembelian"} sx={{ pl: 9 }}>
              <ListItemText>Retur Pembelian</ListItemText>
            </ListItem>
          </Link>
        </Collapse>

        <ListItem onClick={() => setSubmenuMaster(!submenu_master)}>
          <ListItemIcon><FastfoodOutlinedIcon style={{ color: "red" }} /></ListItemIcon>
          <ListItemText>Master</ListItemText>
          {submenu_master === true ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={submenu_master} timeout="auto" unmountOnExit>
          <Link to="/jenis_produk" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              localStorage.setItem("menu", "Jenis Produk");
              localStorage.setItem("submenu_laporan", false);
              localStorage.setItem("submenu_akuntansi", false);
              localStorage.setItem("submenu_transaksi", false);
              localStorage.setItem("submenu_master", true);
              setMenu("Jenis Produk");
              setSubmenuLaporan(false);
              setSubmenuAkuntansi(false);
              setSubmenuTransaksi(false);
              setSubmenuMaster(true);
            }}
          >
            <ListItem selected={menu === "Jenis Produk"} sx={{ pl: 9 }}>
              <ListItemText>Jenis Produk</ListItemText>
            </ListItem>
          </Link>

          <Link to="/produk" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              localStorage.setItem("menu", "Produk");
              localStorage.setItem("submenu_laporan", false);
              localStorage.setItem("submenu_akuntansi", false);
              localStorage.setItem("submenu_transaksi", false);
              localStorage.setItem("submenu_master", true);
              setMenu("Produk");
              setSubmenuLaporan(false);
              setSubmenuAkuntansi(false);
              setSubmenuTransaksi(false);
              setSubmenuMaster(true);
            }}
          >
            <ListItem selected={menu === "Produk"} sx={{ pl: 9 }}>
              <ListItemText>Produk</ListItemText>
            </ListItem>
          </Link>

          <Link to="/pengguna" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              localStorage.setItem("menu", "Pengguna");
              localStorage.setItem("submenu_laporan", false);
              localStorage.setItem("submenu_akuntansi", false);
              localStorage.setItem("submenu_transaksi", false);
              localStorage.setItem("submenu_master", true);
              setMenu("Pengguna");
              setSubmenuLaporan(false);
              setSubmenuAkuntansi(false);
              setSubmenuTransaksi(false);
              setSubmenuMaster(true);
            }}
          >
            <ListItem selected={menu === "Pengguna"} sx={{ pl: 9 }}>
              <ListItemText>Pengguna</ListItemText>
            </ListItem>
          </Link>

          <Link to="/supplier" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              localStorage.setItem("menu", "Supplier");
              localStorage.setItem("submenu_laporan", false);
              localStorage.setItem("submenu_akuntansi", false);
              localStorage.setItem("submenu_transaksi", false);
              localStorage.setItem("submenu_master", true);
              setMenu("Supplier");
              setSubmenuLaporan(false);
              setSubmenuAkuntansi(false);
              setSubmenuTransaksi(false);
              setSubmenuMaster(true);
            }}
          >
            <ListItem selected={menu === "Supplier"} sx={{ pl: 9 }}>
              <ListItemText>Supplier</ListItemText>
            </ListItem>
          </Link>

          <Link to="/metode_bayar" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              localStorage.setItem("menu", "Metode Bayar");
              localStorage.setItem("submenu_laporan", false);
              localStorage.setItem("submenu_akuntansi", false);
              localStorage.setItem("submenu_transaksi", false);
              localStorage.setItem("submenu_master", true);
              setMenu("Metode Bayar");
              setSubmenuLaporan(false);
              setSubmenuAkuntansi(false);
              setSubmenuTransaksi(false);
              setSubmenuMaster(true);
            }}>
            <ListItem selected={menu === "Metode Bayar"} sx={{ pl: 9 }}>
              <ListItemText>Metode Bayar</ListItemText>
            </ListItem>
          </Link>

          <Link to="/karyawan" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              localStorage.setItem("menu", "Karyawan");
              localStorage.setItem("submenu_laporan", false);
              localStorage.setItem("submenu_akuntansi", false);
              localStorage.setItem("submenu_transaksi", false);
              localStorage.setItem("submenu_master", true);
              setMenu("Karyawan");
              setSubmenuLaporan(false);
              setSubmenuAkuntansi(false);
              setSubmenuTransaksi(false);
              setSubmenuMaster(true);
            }}>
            <ListItem selected={menu === "Karyawan"} sx={{ pl: 9 }}>
              <ListItemText>Karyawan</ListItemText>
            </ListItem>
          </Link>
        </Collapse>
      </List>
      <Divider />
      <List>
        <Link to="/pindah_saldo" style={{ textDecoration: "none", color: "black" }}
          onClick={() => {
            localStorage.setItem("menu", "Pindah Saldo");
            localStorage.setItem("submenu_laporan", false);
            localStorage.setItem("submenu_akuntansi", false);
            localStorage.setItem("submenu_transaksi", false);
            localStorage.setItem("submenu_master", false);
            setMenu("Pindah Saldo");
            setSubmenuLaporan(false);
            setSubmenuAkuntansi(false);
            setSubmenuTransaksi(false);
            setSubmenuMaster(false);
          }}
        >
          <ListItem selected={menu === "Pindah Saldo"}>
            <ListItemIcon><MoneyIcon style={{ color: "green" }} /></ListItemIcon>
            <ListItemText>Pindah Saldo</ListItemText>
          </ListItem>
        </Link>
        <Link to="/otorisasi" style={{ textDecoration: "none", color: "black" }}
          onClick={() => {
            localStorage.setItem("menu", "Otorisasi");
            localStorage.setItem("submenu_laporan", false);
            localStorage.setItem("submenu_akuntansi", false);
            localStorage.setItem("submenu_transaksi", false);
            localStorage.setItem("submenu_master", false);
            setMenu("Otorisasi");
            setSubmenuLaporan(false);
            setSubmenuAkuntansi(false);
            setSubmenuTransaksi(false);
            setSubmenuMaster(false);
          }}
        >
          <ListItem selected={menu === "Otorisasi"}>
            <ListItemIcon><VerifiedUserIcon style={{ color: "red" }} /></ListItemIcon>
            <ListItemText>Otorisasi</ListItemText>
          </ListItem>
        </Link>
        <Link to="/open_close" style={{ textDecoration: "none", color: "black" }}
          onClick={() => {
            localStorage.setItem("menu", "Opening / Closing");
            localStorage.setItem("submenu_laporan", false);
            localStorage.setItem("submenu_akuntansi", false);
            localStorage.setItem("submenu_transaksi", false);
            localStorage.setItem("submenu_master", false);
            setMenu("Opening / Closing");
            setSubmenuLaporan(false);
            setSubmenuAkuntansi(false);
            setSubmenuTransaksi(false);
            setSubmenuMaster(false);
          }}
        >
          <ListItem selected={menu === "Opening / Closing"}>
            <ListItemIcon><OpenInBrowserIcon style={{ color: "gray" }} /></ListItemIcon>
            <ListItemText>Opening / Closing</ListItemText>
          </ListItem>
        </Link>
        <Link to="/eom" style={{ textDecoration: "none", color: "black" }}
          onClick={() => {
            localStorage.setItem("menu", "Proses EOM");
            localStorage.setItem("submenu_laporan", false);
            localStorage.setItem("submenu_akuntansi", false);
            localStorage.setItem("submenu_transaksi", false);
            localStorage.setItem("submenu_master", false);
            setMenu("Proses EOM");
            setSubmenuLaporan(false);
            setSubmenuAkuntansi(false);
            setSubmenuTransaksi(false);
            setSubmenuMaster(false);
          }}
        >
          <ListItem selected={menu === "Proses EOM"}>
            <ListItemIcon><AddTaskIcon style={{ color: "purple" }} /></ListItemIcon>
            <ListItemText>Proses EOM</ListItemText>
          </ListItem>
        </Link>
        <Link to="/pengaturan" style={{ textDecoration: "none", color: "black" }}
          onClick={() => {
            localStorage.setItem("menu", "Pengaturan");
            localStorage.setItem("submenu_laporan", false);
            localStorage.setItem("submenu_akuntansi", false);
            localStorage.setItem("submenu_transaksi", false);
            localStorage.setItem("submenu_master", false);
            setMenu("Pengaturan");
            setSubmenuLaporan(false);
            setSubmenuAkuntansi(false);
            setSubmenuTransaksi(false);
            setSubmenuMaster(false);
          }}
        >
          <ListItem selected={menu === "Pengaturan"}>
            <ListItemIcon><SettingsOutlinedIcon style={{ color: "black" }} /></ListItemIcon>
            <ListItemText>Pengaturan</ListItemText>
          </ListItem>
        </Link>
      </List>
    </div>
  );


  if (!localStorage.getItem("login")) {
    return <Navigate to="/login" />;
  }
  else {
    return (
      <div basename={props.basename}>
        <Dialog
          open={tampil_dialog_keluar}
          onClose={() => setTampilDialogKeluar(false)}
        >
          <DialogTitle>
            Konfirmasi
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Apakah anda yakin ingin keluar dari aplikasi?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setTampilDialogKeluar(false)} autoFocus>Tidak</Button>
            <Button
              onClick={() => {
                setTampilDialogKeluar(false);
                props.proses_keluar();
              }}
            >Ya</Button>
          </DialogActions>
        </Dialog>

        <Box
          sx={{
            backgroundColor: "#eff0f0",
            minHeight: '100vh',
            display: 'flex'
          }}
        >
          <CssBaseline />
          <AppBar position="fixed" open={open}
            sx={{
              backgroundColor: "#35b2b8",
              // width: { sm: `calc(100% - ${drawerWidth}px)` },
              // ml: { sm: `${drawerWidth}px` },
            }}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: 'none' }) }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                {menu}
              </Typography>

              <Button color="inherit"
                onClick={() => {
                  setTampilDialogKeluar(true);
                }}
              >
                Keluar
              </Button>
            </Toolbar>
          </AppBar>
          <Drawer
            sx={{
              // display: { xs: 'none', sm: 'block' },
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </DrawerHeader>
            <Divider />
            {drawer}
          </Drawer>

          <Main open={open}>
            <DrawerHeader />

            <Routes>
              <Route
                path='/' exact
                element={
                  <Beranda
                    aplikasi={props.aplikasi}
                  />
                }
              />

              <Route
                path="/beranda"
                element={
                  <Beranda
                    aplikasi={props.aplikasi}
                  />
                }
              />

              <Route
                path="/laporan_penjualan"
                element={
                  <LapPenjualan
                    aplikasi={props.aplikasi}
                  />
                }
              />

              <Route
                path="/laporan_pembelian"
                element={
                  <LapPembelian
                    aplikasi={props.aplikasi}
                  />
                }
              />

              <Route
                path="/laporan_retur_penjualan"
                element={
                  <LapReturPenjualan
                    aplikasi={props.aplikasi}
                  />
                }
              />

              <Route
                path="/laporan_retur_pembelian"
                element={
                  <LapReturPembelian
                    aplikasi={props.aplikasi}
                  />
                }
              />

              <Route
                path="/laporan_penyesuaian_stok"
                element={
                  <LapPenyesuaianStok
                    aplikasi={props.aplikasi}
                  />
                }
              />

              <Route
                path="/laporan_hutang_piutang"
                element={
                  <LapHutangPiutang
                    aplikasi={props.aplikasi}
                  />
                }
              />

              <Route
                path="/laporan_produk"
                element={
                  <LapProduk
                    aplikasi={props.aplikasi}
                  />
                }
              />

              <Route
                path="/laporan_stok"
                element={
                  <LapStok
                    aplikasi={props.aplikasi}
                  />
                }
              />

              <Route
                path="/laporan_supplier"
                element={
                  <LapSupplier
                    aplikasi={props.aplikasi}
                  />
                }
              />


              <Route
                path="/laporan_metode_bayar"
                element={
                  <LapMetodeBayar
                    aplikasi={props.aplikasi}
                  />
                }
              />

              <Route
                path="/laporan_karyawan"
                element={
                  <LapKaryawan
                    aplikasi={props.aplikasi}
                  />
                }
              />

              <Route
                path="/jurnal"
                element={
                  <Jurnal
                    aplikasi={props.aplikasi}
                  />
                }
              />

              <Route
                path="/favorit"
                element={
                  <Favorit
                    aplikasi={props.aplikasi}
                  />
                }
              />

              <Route
                path="/general_ledger"
                element={
                  <GeneralLedger
                    aplikasi={props.aplikasi}
                  />
                }
              />

              <Route
                path="/daftar_transaksi"
                element={
                  <DaftarTransaksi
                    aplikasi={props.aplikasi}
                  />
                }
              />

              <Route
                path="/laporan_keuangan"
                element={
                  <LapKeuangan
                    aplikasi={props.aplikasi}
                  />
                }
              />

              <Route
                path="/tracking_gl"
                element={
                  <TrackingGL
                    aplikasi={props.aplikasi}
                  />
                }
              />

              <Route
                path="/pembelian"
                element={
                  <Pembelian
                    aplikasi={props.aplikasi}
                  />
                }
              />

              <Route
                path="/penyesuaian_stok"
                element={
                  <PenyesuaianStok
                    aplikasi={props.aplikasi}
                  />
                }
              />

              <Route
                path="/hutang_supplier"
                element={
                  <HutangSupplier
                    aplikasi={props.aplikasi}
                  />
                }
              />

              <Route
                path="/retur_pembelian"
                element={
                  <ReturPembelian
                    aplikasi={props.aplikasi}
                  />
                }
              />

              <Route
                path="/jenis_produk"
                element={
                  <JenisProduk
                    aplikasi={props.aplikasi}
                    file={props.file}
                  />
                }
              />

              <Route
                path="/produk"
                element={
                  <Produk
                    aplikasi={props.aplikasi}
                    file={props.file}
                  />
                }
              />

              <Route
                path="/pengguna"
                element={
                  <Pengguna
                    aplikasi={props.aplikasi}
                    file={props.file}
                  />
                }
              />

              <Route
                path="/supplier"
                element={
                  <Supplier
                    aplikasi={props.aplikasi}
                    file={props.file}
                  />
                }
              />

              <Route
                path="/metode_bayar"
                element={
                  <MetodeBayar
                    aplikasi={props.aplikasi}
                    file={props.file} />
                }
              />

              <Route
                path="/karyawan"
                element={
                  <Karyawan
                    aplikasi={props.aplikasi}
                    file={props.file} />
                }
              />

              <Route
                path="/pindah_saldo"
                element={
                  <PindahSaldo
                    aplikasi={props.aplikasi}
                  />
                }
              />

              <Route
                path="/otorisasi"
                element={
                  <Otorisasi
                    aplikasi={props.aplikasi}
                  />
                }
              />

              <Route
                path="/open_close"
                element={
                  <OpenClose
                    aplikasi={props.aplikasi}
                  />
                }
              />

              <Route
                path="/eom"
                element={
                  <Eom
                    aplikasi={props.aplikasi}
                  />
                }
              />

              <Route
                path="/pengaturan"
                element={
                  <Pengaturan
                    aplikasi={props.aplikasi}
                    file={props.file}
                  />
                }
              />
            </Routes>
          </Main>
        </Box>
      </div>
    );
  }
}

export default Utama;