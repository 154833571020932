import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

//gambar
import AddOutlined from '@mui/icons-material/AddOutlined';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

function Karyawan(props, ref) {
    //state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [tampil_dialog, setTampilDialog] = useState(false);
    const [data_karyawan, setDataKaryawan] = useState([]);
    const [pencarian, setPencarian] = useState("");
    const [id_karyawan, setIdKaryawan] = useState("");
    const [nik, setNik] = useState("");
    const [nama, setNama] = useState("");
    const [bagian, setBagian] = useState("");
    const [keanggotaan, setKeanggotaan] = useState("RS");
    const [status, setStatus] = useState(false);
    const [label_status, setLabelStatus] = useState("Nonaktif");
    const [tampil_dialog_hapus, setTampilDialogHapus] = useState(false);
    const [jumlah_baris] = useState(40);
    const [jumlah_halaman, setJumlahHalaman] = useState(0);
    const [halaman, setHalaman] = useState(1);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/karyawan/tampil_karyawan_limit.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: "",
                        cari: "",
                        jumlah_baris: 40,
                        halaman: 1
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataKaryawan(data.data);
                    setJumlahHalaman(Math.ceil(data.jumlah_data / 40));
                }
                else {
                    setDataKaryawan([]);
                    setJumlahHalaman(0);
                }
            })
            .catch(error => {
                setDataKaryawan([]);
                setJumlahHalaman(0);
            }
            );
    }, [props.aplikasi]);

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const tampil_karyawan = (halaman_baru) => {
        fetch(props.aplikasi + '/karyawan/tampil_karyawan_limit.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: "",
                        cari: pencarian,
                        jumlah_baris: jumlah_baris,
                        halaman: halaman_baru
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataKaryawan(data.data);
                    setJumlahHalaman(Math.ceil(data.jumlah_data / jumlah_baris));
                }
                else {
                    setDataKaryawan([]);
                    setJumlahHalaman(0);
                }
            })
            .catch(error => {
                setDataKaryawan([]);
                setJumlahHalaman(0);
            }
            );
    }

    const pindah_halaman = (halaman_baru) => {
        setHalaman(halaman_baru);
        tampil_karyawan(halaman_baru);
    }

    const cari = () => {
        setHalaman(1);
        tampil_karyawan(1);
    }

    const bersih_input_karyawan = () => {
        setIdKaryawan("");
        setNik("");
        setNama("");
        setBagian("");
        setKeanggotaan("RS");
        setStatus(false);
        setLabelStatus("Nonaktif");
    }

    const simpan = () => {
        if (nik.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("No. Karyawan wajib diisi");
            setTampilNotif(true);
            document.getElementById("nik").focus();
            return;
        }

        if (nama.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Nama karyawan wajib diisi");
            setTampilNotif(true);
            document.getElementById("nama").focus();
            return;
        }

        if (bagian.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Bagian wajib diisi");
            setTampilNotif(true);
            document.getElementById("bagian").focus();
            return;
        }

        setProses(true);
        if (id_karyawan === "") {
            fetch(props.aplikasi + '/karyawan/simpan_karyawan.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "simpan",
                            id_karyawan: "",
                            nik: nik,
                            nama: nama,
                            bagian: bagian,
                            keanggotaan: keanggotaan,
                            status: status
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        bersih_input_karyawan();
                        tampil_karyawan(1);
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nik").focus();
                        setProses(false);
                    }
                    else {
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nik").focus();
                        setProses(false);
                    }
                })
                .catch(error => {
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("nik").focus();
                    setProses(false);
                }
                );
        }
        else {
            fetch(props.aplikasi + '/karyawan/simpan_karyawan.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "perbarui",
                            id_karyawan: id_karyawan,
                            nik: nik,
                            nama: nama,
                            bagian: bagian,
                            keanggotaan: keanggotaan,
                            status: status
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        tampil_karyawan(1);
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nik").focus();
                        setProses(false);
                    }
                    else {
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nik").focus();
                        setProses(false);
                    }
                })
                .catch(error => {
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("nik").focus();
                    setProses(false);
                }
                );
        }
    }

    const hapus = () => {
        setProses(true);
        fetch(props.aplikasi + '/karyawan/hapus_karyawan.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_karyawan: id_karyawan
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    bersih_input_karyawan();
                    tampil_karyawan(1);
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    document.getElementById("nik").focus();
                    setProses(false);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    document.getElementById("nik").focus();
                    setProses(false);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                document.getElementById("nik").focus();
                setProses(false);
            }
            );
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Fab
                    color="primary"
                    sx={{
                        background: "#37ce9b",
                        border: "#37ce9b",
                        margin: 0,
                        top: "auto",
                        right: 20,
                        bottom: 20,
                        left: "auto",
                        position: "fixed",
                        zIndex: "99"
                    }}
                    onClick={() => {
                        bersih_input_karyawan();
                        setTampilDialog(true);
                    }}
                >
                    <AddOutlined />
                </Fab>

                <Card
                    sx={{ padding: "10px" }}
                >
                    <TextField
                        id="input-with-icon-textfield"
                        label="Cari berdasarkan nama karyawan"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchOutlined />
                                </InputAdornment>
                            ),
                        }}
                        value={pencarian}
                        onChange={(event) => setPencarian(event.target.value)}
                        onKeyPress={(event) => {
                            if (event.key === "Enter") {
                                cari();
                            }
                        }}
                    />
                </Card>

                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    {data_karyawan
                        .map((tampung_produk, index) => {
                            return (
                                <Grid
                                    item xs={12} sm={6} md={4} lg={3}
                                    key={index}
                                    onClick={() => {
                                        bersih_input_karyawan();
                                        setIdKaryawan(tampung_produk.id_karyawan);
                                        setNik(tampung_produk.nik);
                                        setNama(tampung_produk.nama);
                                        setBagian(tampung_produk.bagian);
                                        setKeanggotaan(tampung_produk.keanggotaan);
                                        if (tampung_produk.status === "1") {
                                            setStatus(true);
                                            setLabelStatus("Aktif");
                                        }
                                        else {
                                            setStatus(false);
                                            setLabelStatus("Nonaktif");
                                        }
                                        setTampilDialog(true);
                                    }}
                                >
                                    <Card>
                                        <List>
                                            <ListItem button>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="body1" noWrap>{tampung_produk.nama}</Typography>
                                                    }
                                                    secondary={
                                                        <Typography variant="subtitle2" noWrap>{tampung_produk.bagian} - {tampung_produk.keanggotaan}</Typography>
                                                    }
                                                >
                                                </ListItemText>
                                            </ListItem>
                                        </List>
                                    </Card>
                                </Grid>
                            );
                        })}
                </Grid>

                <Grid
                    container item xs={12}
                    justifyContent="center"
                    sx={{
                        marginBottom: "10px"
                    }}
                >
                    <Stack spacing={2}>
                        <Pagination
                            color="primary"
                            count={jumlah_halaman}
                            page={halaman}
                            onChange={(event, nomor) => {
                                pindah_halaman(nomor);
                            }}
                        />
                    </Stack>
                </Grid>
            </Box>

            <Dialog
                open={tampil_dialog}
                onClose={() => setTampilDialog(false)}
            >
                <DialogTitle>Input Data Karyawan</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography variant="body2">
                                        No. Karyawan
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <TextField
                                        id="nik"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        value={nik}
                                        onChange={(event) => setNik(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Nama
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <TextField
                                        id="nama"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        value={nama}
                                        onChange={(event) => setNama(event.target.value)}
                                    />
                                </Grid>
                            </Grid>                            

                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Bagian
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <TextField
                                        id="bagian"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        value={bagian}
                                        onChange={(event) => setBagian(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Keanggotaan
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <Select
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        value={keanggotaan}
                                        onChange={(event) => setKeanggotaan(event.target.value)}
                                    >
                                        <MenuItem value="RS">Karyawan RS</MenuItem>
                                        <MenuItem value="TK">Karyawan TK</MenuItem>
                                        <MenuItem value="Lainnya">Lainnya</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Status
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={status}
                                                onChange={(event) => {
                                                    if (status === false) {
                                                        setStatus(true);
                                                        setLabelStatus("Aktif");
                                                    }
                                                    else {
                                                        setStatus(false);
                                                        setLabelStatus("Nonaktif");
                                                    }
                                                }}
                                            />
                                        }

                                        label={label_status}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                        loading={proses}
                        onClick={() => simpan()}
                    >
                        <Typography variant="body2" noWrap={true}>{id_karyawan === "" ? "Simpan" : "Perbarui"}</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<DeleteIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "red" }}
                        loading={proses}
                        onClick={() => setTampilDialogHapus(true)}
                    >
                        <Typography variant="body2" noWrap={true}>Hapus</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        loading={proses}
                        onClick={() => setTampilDialog(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_hapus}
                onClose={() => setTampilDialogHapus(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Hapus Produk
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin menghapus data ini?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loading={proses}
                        onClick={() => setTampilDialogHapus(false)}
                        autoFocus
                    >
                        Tidak
                    </LoadingButton>
                    <LoadingButton
                        loading={proses}
                        onClick={() => {
                            setTampilDialogHapus(false);
                            setTampilDialog(false);
                            hapus();
                        }}
                    >
                        Ya
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default forwardRef(Karyawan);