import React, { useState, forwardRef } from 'react';

//komponen
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';

//gambar
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CachedIcon from '@mui/icons-material/Cached';
import SearchIcon from '@mui/icons-material/Search';
import HistoryIcon from '@mui/icons-material/History';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgba(108,122,224,255)",
        borderColor: "rgba(108,122,224,255)",
        color: "white"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "rgba(248,246,255,255)",
        borderColor: "rgba(248,246,255,255)"
    },
}));

function Retur_Penjualan(props, ref) {
    //state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [cari_id_penjualan, setCariIdPenjualan] = useState("");
    const [id_penjualan, setIdPenjualan] = useState("");
    const [waktu_transaksi, setWaktuTransaksi] = useState("");
    const [pelanggan, setPelanggan] = useState("");
    const [metode_bayar, setMetodeBayar] = useState("");
    const [metode_bayar2, setMetodeBayar2] = useState("");
    const [penjualan_grand_total, setPenjualanGrandTotal] = useState(0);
    const [data_retur, setDataRetur] = useState([]);
    const [total, setTotal] = useState(0);
    const [pilih_pembulatan, setPilihPembulatan] = useState(false);
    const [pembulatan, setPembulatan] = useState(0);
    const [grand_total, setGrandTotal] = useState(0);

    const [tampil_dialog_batal, setTampilDialogBatal] = useState(false);
    const [tampil_dialog_retur, setTampilDialogRetur] = useState(false);

    //fungsi
    const tampil_foto = () => {
        if (localStorage.foto !== "null") {
            return props.file + "/Foto/Pengguna/" + localStorage.foto;
        }
    }

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(2)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    const format_tanggal = (waktu, format) => {
        let tahun = waktu.substring(0, 4);
        let bulan = waktu.substring(5, 7);
        let tanggal = waktu.substring(8, 10);
        let jam = waktu.substring(11, 13);
        let menit = waktu.substring(14, 16);
        let detik = waktu.substring(17, 19);

        if (format === "short") {
            return tanggal + "-" + bulan + "-" + tahun;
        }
        else {
            return tanggal + "-" + bulan + "-" + tahun + " " + jam + ":" + menit + ":" + detik;
        }
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const hitung_pembulatan = (vartotal, vardiskon) => {
        let tampung_grand_total = parseFloat(vartotal) - parseFloat(vardiskon);
        let tampung_pembulatan = Math.round(tampung_grand_total / 100) * 100 - tampung_grand_total;
        setPembulatan(tampung_pembulatan);
        setGrandTotal(vartotal - vardiskon + tampung_pembulatan);
    }

    const pilih_retur = (event, id_produk) => {
        let tampung = [...data_retur];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_produk"] === id_produk) {
                tampung[awal - 1]["retur"] = event.target.checked;
                if (event.target.checked === false) {
                    tampung[awal - 1]["metode_retur"] = "Barang";
                    tampung[awal - 1]["jumlah_retur"] = 0;
                }
                break;
            }
            awal++;
        }

        let tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["retur"] === true && tampung[awal - 1]["metode_retur"] === "Uang") {
                tampung_total = tampung_total + ((parseFloat(tampung[awal - 1]["harga_jual"]) - parseFloat(tampung[awal - 1]["diskon"])) * parseFloat(tampung[awal - 1]["jumlah_retur"]));
            }
            awal++;
        }

        setDataRetur(tampung);
        setTotal(tampung_total);
        if (pilih_pembulatan === true) {
            hitung_pembulatan(tampung_total, 0);
        }
        else {
            setPembulatan(0);
            setGrandTotal(tampung_total);
        }
    }

    const ubah_jenis = (event, id_produk) => {
        let tampung = [...data_retur];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_produk"] === id_produk) {
                tampung[awal - 1]["metode_retur"] = event.target.value;
                break;
            }
            awal++;
        }

        let tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["retur"] === true && tampung[awal - 1]["metode_retur"] === "Uang") {
                tampung_total = tampung_total + ((parseFloat(tampung[awal - 1]["harga_jual"]) - parseFloat(tampung[awal - 1]["diskon"])) * parseFloat(tampung[awal - 1]["jumlah_retur"]));
            }
            awal++;
        }

        setDataRetur(tampung);
        setTotal(tampung_total);
        if (pilih_pembulatan === true) {
            hitung_pembulatan(tampung_total, 0);
        }
        else {
            setPembulatan(0);
            setGrandTotal(tampung_total);
        }
    }

    const tambah_jumlah_retur = (id_produk) => {
        let tampung = [...data_retur];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_produk"] === id_produk) {
                if (parseFloat(tampung[awal - 1]["jumlah_retur"]) + 1 > parseFloat(tampung[awal - 1]["jumlah"])) {
                    return;
                }
                tampung[awal - 1]["jumlah_retur"] = parseFloat(tampung[awal - 1]["jumlah_retur"]) + 1;
                break;
            }
            awal++;
        }

        let tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["retur"] === true && tampung[awal - 1]["metode_retur"] === "Uang") {
                tampung_total = tampung_total + ((parseFloat(tampung[awal - 1]["harga_jual"]) - parseFloat(tampung[awal - 1]["diskon"])) * parseFloat(tampung[awal - 1]["jumlah_retur"]));
            }
            awal++;
        }

        setDataRetur(tampung);
        setTotal(tampung_total);
        if (pilih_pembulatan === true) {
            hitung_pembulatan(tampung_total, 0);
        }
        else {
            setPembulatan(0);
            setGrandTotal(tampung_total);
        }
    }

    const kurang_jumlah_retur = (id_produk) => {
        let tampung = [...data_retur];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_produk"] === id_produk) {
                if (parseFloat(tampung[awal - 1]["jumlah_retur"]) - 1 < 1) {
                    return;
                }
                tampung[awal - 1]["jumlah_retur"] = parseFloat(tampung[awal - 1]["jumlah_retur"]) - 1;
                break;
            }
            awal++;
        }

        let tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["retur"] === true && tampung[awal - 1]["metode_retur"] === "Uang") {
                tampung_total = tampung_total + ((parseFloat(tampung[awal - 1]["harga_jual"]) - parseFloat(tampung[awal - 1]["diskon"])) * parseFloat(tampung[awal - 1]["jumlah_retur"]));
            }
            awal++;
        }

        setDataRetur(tampung);
        setTotal(tampung_total);
        if (pilih_pembulatan === true) {
            hitung_pembulatan(tampung_total, 0);
        }
        else {
            setPembulatan(0);
            setGrandTotal(tampung_total);
        }
    }

    const ubah_jumlah_retur = (event, id_produk) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }

        let tampung = [...data_retur];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_produk"] === id_produk) {
                // if (parseFloat(event.target.value) > parseFloat(tampung[awal - 1]["jumlah"])) {
                //     return;
                // }
                tampung[awal - 1]["jumlah_retur"] = event.target.value;
                break;
            }
            awal++;
        }

        let tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["retur"] === true && tampung[awal - 1]["metode_retur"] === "Uang") {
                tampung_total = tampung_total + ((parseFloat(tampung[awal - 1]["harga_jual"]) - parseFloat(tampung[awal - 1]["diskon"])) * parseFloat(tampung[awal - 1]["jumlah_retur"]));
            }
            awal++;
        }

        setDataRetur(tampung);
        setTotal(tampung_total);
        if (pilih_pembulatan === true) {
            hitung_pembulatan(tampung_total, 0);
        }
        else {
            setPembulatan(0);
            setGrandTotal(tampung_total);
        }
    }

    const perbaiki_jumlah_retur = (event, id_produk) => {
        let tampung = [...data_retur];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_produk"] === id_produk) {
                if (parseFloat(event.target.value) > 0) {
                    if (parseFloat(event.target.value) > parseFloat(tampung[awal - 1]["jumlah"])) {
                        tampung[awal - 1]["jumlah_retur"] = tampung[awal - 1]["jumlah"];
                    }
                    else {
                        tampung[awal - 1]["jumlah_retur"] = event.target.value;
                    }
                }
                else {
                    tampung[awal - 1]["jumlah_retur"] = 1;
                }

                break;
            }
            awal++;
        }

        let tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["retur"] === true && tampung[awal - 1]["metode_retur"] === "Uang") {
                tampung_total = tampung_total + ((parseFloat(tampung[awal - 1]["harga_jual"]) - parseFloat(tampung[awal - 1]["diskon"])) * parseFloat(tampung[awal - 1]["jumlah_retur"]));
            }
            awal++;
        }

        setDataRetur(tampung);
        setTotal(tampung_total);
        if (pilih_pembulatan === true) {
            hitung_pembulatan(tampung_total, 0);
        }
        else {
            setPembulatan(0);
            setGrandTotal(tampung_total);
        }
    }

    const ubah_catatan_retur = (event, id_produk) => {
        let tampung = [...data_retur];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_produk"] === id_produk) {
                // if (parseFloat(event.target.value) > parseFloat(tampung[awal - 1]["jumlah"])) {
                //     return;
                // }
                tampung[awal - 1]["catatan"] = event.target.value;
                break;
            }
            awal++;
        }

        setDataRetur(tampung);
    }

    const batal = () => {
        setCariIdPenjualan("");
        setIdPenjualan("");
        setWaktuTransaksi("");
        setPelanggan("");
        setMetodeBayar("");
        setMetodeBayar2("");
        setPenjualanGrandTotal(0);
        setDataRetur([]);
        setTotal(0);
        setPilihPembulatan(false);
        setPembulatan(0);
        setGrandTotal(0);
    }

    const simpan = () => {
        fetch(props.aplikasi + '/produk/simpan_retur_penjualan.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_penjualan: id_penjualan,
                        subtotal: total,
                        pembulatan: pembulatan,
                        grand_total: grand_total,
                        data_retur: data_retur,
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setTampilDialogRetur(false);
                    batal();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
            }
            );
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Dialog
                open={tampil_dialog_batal}
                onClose={() => setTampilDialogBatal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Batal Transaksi
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin membatalkan transaksi ini?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => setTampilDialogBatal(false)}
                        autoFocus
                    >
                        Tidak
                    </LoadingButton>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => {
                            setTampilDialogBatal(false);
                            batal();
                        }}
                    >
                        Ya
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_retur}
                onClose={() => setTampilDialogRetur(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Retur penjualan
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin melakukan retur?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => setTampilDialogRetur(false)}
                        autoFocus
                    >
                        Tidak
                    </LoadingButton>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => {
                            setTampilDialogRetur(false);
                            simpan();
                        }}
                    >
                        Ya
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Card
                                    sx={{
                                        backgroundColor: "white",
                                        padding: "10px"
                                    }}
                                >
                                    <Grid container item>
                                        <Grid item xs={4} md={3}>
                                            <Typography variant="body2">
                                                No. Bukti
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={4} md={3}>
                                            <TextField
                                                id="id_penjualan"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                value={cari_id_penjualan}
                                                onChange={(e) => setCariIdPenjualan(e.target.value)}
                                                onKeyPress={(event) => {
                                                    if (event.key === "Enter") {
                                                        if (cari_id_penjualan === "") {
                                                            setJenisNotif("warning");
                                                            setIsiNotif("No. Bukti wajib diisi");
                                                            document.getElementById("id_penjualan").focus();
                                                            setTampilNotif(true);
                                                        }
                                                        else {
                                                            setProses(true);
                                                            fetch(props.aplikasi + '/produk/tampil_penjualan.php',
                                                                {
                                                                    method: 'POST',
                                                                    body: JSON.stringify
                                                                        ({
                                                                            id_penjualan: cari_id_penjualan
                                                                        }),
                                                                    headers:
                                                                    {
                                                                        "Content-type": "application/json; charset=UTF-8"
                                                                    }
                                                                })
                                                                .then(response => response.json())
                                                                .then(data => {
                                                                    if (data.kode === 1) {
                                                                        setIdPenjualan(data.data.penjualan.id_penjualan);
                                                                        setWaktuTransaksi(format_tanggal(data.data.penjualan.waktu_transaksi, "long"));
                                                                        setPelanggan(data.data.penjualan.nama_pelanggan);
                                                                        setMetodeBayar(data.data.penjualan.metode_bayar);
                                                                        setMetodeBayar2(data.data.penjualan.metode_bayar2);
                                                                        setPenjualanGrandTotal(format_rupiah(parseFloat(data.data.penjualan.grand_total)));
                                                                        setDataRetur(data.data.penjualan_detail);
                                                                        setProses(false);
                                                                    }
                                                                    else {
                                                                        setJenisNotif("warning");
                                                                        setIsiNotif(data.pesan);
                                                                        setTampilNotif(true);
                                                                        setProses(false);
                                                                    }
                                                                })
                                                                .catch(error => {
                                                                    setJenisNotif("error");
                                                                    setIsiNotif("Terjadi kesalahan");
                                                                    setTampilNotif(true);
                                                                    setProses(false);
                                                                }
                                                                );
                                                        }
                                                    }
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={4} md={3}>
                                            <LoadingButton
                                                loadingPosition="start"
                                                variant="contained"
                                                color="primary"
                                                startIcon={<SearchIcon />}
                                                sx={{ marginLeft: "5px", borderRadius: "5em", backgroundColor: "#fdcc51" }}
                                                loading={proses}
                                                onClick={() => {
                                                    if (cari_id_penjualan === "") {
                                                        setJenisNotif("warning");
                                                        setIsiNotif("No. Bukti wajib diisi");
                                                        document.getElementById("id_penjualan").focus();
                                                        setTampilNotif(true);
                                                    }
                                                    else {
                                                        setProses(true);
                                                        fetch(props.aplikasi + '/produk/tampil_penjualan.php',
                                                            {
                                                                method: 'POST',
                                                                body: JSON.stringify
                                                                    ({
                                                                        id_penjualan: cari_id_penjualan
                                                                    }),
                                                                headers:
                                                                {
                                                                    "Content-type": "application/json; charset=UTF-8"
                                                                }
                                                            })
                                                            .then(response => response.json())
                                                            .then(data => {
                                                                if (data.kode === 1) {
                                                                    setIdPenjualan(data.data.penjualan.id_penjualan);
                                                                    setWaktuTransaksi(format_tanggal(data.data.penjualan.waktu_transaksi, "long"));
                                                                    setPelanggan(data.data.penjualan.nama_anggota);
                                                                    setMetodeBayar(data.data.penjualan.metode_bayar);
                                                                    setMetodeBayar2(data.data.penjualan.metode_bayar2);
                                                                    setPenjualanGrandTotal(format_rupiah(parseFloat(data.data.penjualan.grand_total)));
                                                                    setDataRetur(data.data.penjualan_detail);
                                                                    setTotal(0);
                                                                    setPilihPembulatan(false);
                                                                    setPembulatan(0);
                                                                    setGrandTotal(0);
                                                                    setProses(false);
                                                                }
                                                                else {
                                                                    setJenisNotif("warning");
                                                                    setIsiNotif(data.pesan);
                                                                    setTampilNotif(true);
                                                                    setProses(false);
                                                                }
                                                            })
                                                            .catch(error => {
                                                                setJenisNotif("error");
                                                                setIsiNotif("Terjadi kesalahan");
                                                                setTampilNotif(true);
                                                                setProses(false);
                                                            }
                                                            );
                                                    }
                                                }}
                                            >
                                                Cari
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <Card
                                    sx={{
                                        backgroundColor: "white",
                                        padding: "10px",
                                        display: "flex",
                                        overflowY: "auto",
                                        maxHeight: "calc(100vh - 165px)"
                                    }}
                                >
                                    <TableContainer component={Paper}>
                                        <Table stickyHeader size="small" aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="center">Retur</StyledTableCell>
                                                    <StyledTableCell align="center">Penukaran</StyledTableCell>
                                                    <StyledTableCell align="center">Jumlah Tukar</StyledTableCell>
                                                    <StyledTableCell align="center">Catatan</StyledTableCell>
                                                    <StyledTableCell align="center">Nama</StyledTableCell>
                                                    <StyledTableCell align="center">Harga Penjualan</StyledTableCell>
                                                    <StyledTableCell align="center">Jumlah Penjualan</StyledTableCell>
                                                    <StyledTableCell align="center">Satuan</StyledTableCell>
                                                    <StyledTableCell align="center">Subtotal Penjualan</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data_retur.map((tampung_retur) => {
                                                    return (
                                                        <StyledTableRow key={tampung_retur.id_produk}>
                                                            <TableCell size="small" align="center">
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={tampung_retur.retur === true}
                                                                            onChange={(e) => pilih_retur(e, tampung_retur.id_produk)}
                                                                        />
                                                                    }
                                                                />
                                                            </TableCell>
                                                            <TableCell size="small">
                                                                <Select
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    size="small"
                                                                    disabled={tampung_retur.retur === true ? false : true}
                                                                    value={tampung_retur.metode_retur}
                                                                    onChange={(e) => ubah_jenis(e, tampung_retur.id_produk)}
                                                                >
                                                                    <MenuItem value="Barang">Barang</MenuItem>
                                                                    <MenuItem value="Uang">Uang</MenuItem>
                                                                </Select>
                                                            </TableCell>

                                                            <TableCell size="small" align="center" sx={{ whiteSpace: "nowrap" }}>
                                                                <Fab
                                                                    color="primary"
                                                                    size="small"
                                                                    disabled={tampung_retur.retur === true ? false : true}
                                                                    style={{ backgroundColor: "gray" }}
                                                                    onClick={() => kurang_jumlah_retur(tampung_retur.id_produk)}
                                                                >
                                                                    <RemoveIcon />
                                                                </Fab>
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    inputProps={{
                                                                        style: {
                                                                            textAlign: "center",
                                                                            width: "50px"
                                                                        }
                                                                    }}
                                                                    disabled={tampung_retur.retur === true ? false : true}
                                                                    value={tampung_retur.jumlah_retur}
                                                                    onChange={(e) => ubah_jumlah_retur(e, tampung_retur.id_produk)}
                                                                    onBlur={(e) => perbaiki_jumlah_retur(e, tampung_retur.id_produk)}
                                                                />
                                                                <Fab
                                                                    color="primary"
                                                                    size="small"
                                                                    disabled={tampung_retur.retur === true ? false : true}
                                                                    style={{ backgroundColor: "gray" }}
                                                                    onClick={() => tambah_jumlah_retur(tampung_retur.id_produk)}
                                                                >
                                                                    <AddIcon />
                                                                </Fab>
                                                            </TableCell>

                                                            <TableCell size="small" align="center" sx={{ whiteSpace: "nowrap" }}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    inputProps={{
                                                                        style: {
                                                                            textAlign: "center",
                                                                            width: "100px"
                                                                        }
                                                                    }}
                                                                    disabled={tampung_retur.retur === true ? false : true}
                                                                    value={tampung_retur.catatan}
                                                                    onChange={(e) => ubah_catatan_retur(e, tampung_retur.id_produk)}
                                                                />
                                                            </TableCell>

                                                            <TableCell size="small"><Typography variant="body2" noWrap={true}>{tampung_retur.nama}</Typography></TableCell>
                                                            <TableCell size="small" align="right">
                                                                <Typography variant="body2" noWrap={true}>Rp. {format_rupiah(parseFloat(tampung_retur.harga_jual))}</Typography>
                                                                {parseFloat(tampung_retur.diskon) !== 0
                                                                    ?
                                                                    <Typography variant="body2" noWrap={true} color="red">- Rp. {format_rupiah(parseFloat(tampung_retur.diskon))}</Typography>
                                                                    :
                                                                    <></>
                                                                }
                                                            </TableCell>
                                                            <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{format_rupiah(parseFloat(tampung_retur.jumlah))}</Typography></TableCell>
                                                            <TableCell size="small" align="center"><Typography variant="body2" noWrap={true}>{tampung_retur.satuan}</Typography></TableCell>
                                                            <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>Rp. {format_rupiah(parseFloat(tampung_retur.jumlah) * (parseFloat(tampung_retur.harga_jual) - parseFloat(tampung_retur.diskon)))}</Typography></TableCell>
                                                        </StyledTableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Card>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Card
                                            sx={{
                                                backgroundColor: "white",
                                                padding: "10px"
                                            }}
                                        >
                                            <center>
                                                <Avatar
                                                    src={tampil_foto()}
                                                    sx={{ width: "10vmin", height: "10vmin" }}
                                                />
                                                <Typography variant="h6">
                                                    {localStorage.nama}
                                                </Typography>
                                            </center>

                                            <Divider
                                                sx={{
                                                    marginTop: "10px",
                                                    marginBottom: "10px"
                                                }}
                                            />

                                            <Grid container item spacing={1}>
                                                <Grid container item>
                                                    <Grid item xs={12} sm={4} md={3}>
                                                        <Typography variant="caption">
                                                            No. Bukti
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={8} md={9}>
                                                        <Typography variant="caption">
                                                            : {id_penjualan}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid container item>
                                                    <Grid item xs={12} sm={4} md={3}>
                                                        <Typography variant="caption">
                                                            Waktu Transaksi
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={8} md={9}>
                                                        <Typography variant="caption">
                                                            : {waktu_transaksi}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid container item>
                                                    <Grid item xs={12} sm={4} md={3}>
                                                        <Typography variant="caption">
                                                            Pelanggan
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={8} md={9}>
                                                        <Typography variant="caption">
                                                            : {pelanggan !== "" ? pelanggan : "Umum"}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid container item>
                                                    <Grid item xs={12} sm={4} md={3}>
                                                        <Typography variant="caption">
                                                            Metode Bayar 1
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={8} md={9}>
                                                        <Typography variant="caption">
                                                            : {metode_bayar}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid container item>
                                                    <Grid item xs={12} sm={4} md={3}>
                                                        <Typography variant="caption">
                                                            Metode Bayar 2
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={8} md={9}>
                                                        <Typography variant="caption">
                                                            : {metode_bayar2}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid container item>
                                                    <Grid item xs={12} sm={4} md={3}>
                                                        <Typography variant="caption">
                                                            Grand Total
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={8} md={9}>
                                                        <Typography variant="caption">
                                                            : Rp. {penjualan_grand_total}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Card
                                            sx={{
                                                backgroundColor: "white",
                                                padding: "10px",
                                            }}
                                        >
                                            <Grid container spacing={1}>
                                                <Grid item xs={4}>
                                                    <Typography variant="h6">Total Retur</Typography>
                                                </Grid>

                                                <Grid item xs={8}>
                                                    <Typography variant="h6" align="right" noWrap={true} sx={{ color: "green" }}>{format_rupiah(total)}</Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={1}>
                                                <Grid item xs={4}>
                                                    <FormControlLabel control={<Checkbox
                                                        checked={pilih_pembulatan}
                                                        onChange={(event) => {
                                                            if (event.target.checked === true) {
                                                                hitung_pembulatan(total, 0);
                                                            }
                                                            else {
                                                                setPembulatan(0);
                                                                setGrandTotal(total);
                                                            }
                                                            setPilihPembulatan(event.target.checked);
                                                        }}
                                                    />} label={<Typography variant="h6">Pembulatan</Typography>} />
                                                </Grid>

                                                <Grid item xs={8}>
                                                    <Typography variant="h6" align="right" noWrap={true} sx={{ color: "green" }}>{format_rupiah(parseFloat(pembulatan))}</Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={1}>
                                                <Grid item xs={4}>
                                                    <Typography variant="h6">Grand Total</Typography>
                                                </Grid>

                                                <Grid item xs={8}>
                                                    <Typography variant="h4" align="right" noWrap={true} sx={{ color: "red" }}>{format_rupiah(parseFloat(grand_total))}</Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container item xs={12} justifyContent="center">
                                                <LoadingButton
                                                    loadingPosition="start"
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<HistoryIcon />}
                                                    sx={{ marginRight: "5px", marginTop: "10px", borderRadius: "5em" }}
                                                    loading={proses}
                                                    onClick={() => {
                                                        let awal = 1;
                                                        let akhir = data_retur.length;
                                                        let dipilih = "";
                                                        while (awal <= akhir) {
                                                            if (data_retur[awal - 1]["retur"] === true) {
                                                                dipilih = "ada";
                                                                break;
                                                            }
                                                            awal++;
                                                        }

                                                        if (dipilih !== "") {
                                                            setTampilDialogRetur(true);
                                                        }
                                                    }}
                                                >
                                                    <Typography component={"span"} variant="h6" noWrap={true}>Retur</Typography>
                                                </LoadingButton>

                                                <LoadingButton
                                                    loadingPosition="start"
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<CachedIcon />}
                                                    sx={{ marginRight: "5px", marginTop: "10px", borderRadius: "5em", backgroundColor: "tomato" }}
                                                    loading={proses}
                                                    onClick={() => {
                                                        setTampilDialogBatal(true);
                                                    }}
                                                >
                                                    <Typography component={"span"} variant="h6" noWrap={true}>Batal</Typography>
                                                </LoadingButton>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default forwardRef(Retur_Penjualan);