import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import { withStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

//gambar
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "rgba(108,122,224,255)",
        borderColor: "rgba(108,122,224,255)",
        color: "white"
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "rgba(248,246,255,255)",
            borderColor: "rgba(248,246,255,255)"
        },
    },
}))(TableRow);

function Riwayat_Retur(props, ref) {
    //state
    const [periode1, setPeriode1] = useState(new Date());
    const [periode2, setPeriode2] = useState(new Date());
    const [data_penjualan, setDataPenjualan] = useState([]);
    const [id_retur, setIdRetur] = useState("");
    const [id_penjualan, setIdPenjualan] = useState("");
    const [nama_anggota, setNamaAnggota] = useState("");
    const [kasir, setKasir] = useState("");
    const [waktu_transaksi, setWaktuTransaksi] = useState("");
    const [data_penjualan_detail, setDataPenjualanDetail] = useState([]);
    const [total, setTotal] = useState(0);
    const [pembulatan, setPembulatan] = useState(0);
    const [grand_total, setGrandTotal] = useState(0);
    const [tampil_dialog, setTampilDialog] = useState(false);

    //fungsi    
    useEffect(() => {
        var tahun = String(periode1.getFullYear());
        var bulan = String(periode1.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        var tanggal = String(periode1.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode1 = tahun + "-" + bulan + "-" + tanggal;

        tahun = String(periode2.getFullYear());
        bulan = String(periode2.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        tanggal = String(periode2.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode2 = tahun + "-" + bulan + "-" + tanggal;

        fetch(props.aplikasi + '/kasir/tampil_riwayat_retur.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pengguna: localStorage.getItem("id_pengguna"),
                        periode1: format_periode1,
                        periode2: format_periode2
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataPenjualan(data.data);
                }
                else {
                    setDataPenjualan([]);
                }
            })
            .catch(error => {
                setDataPenjualan([]);
            }
            );        

        const auto_refresh = setInterval(() => {
            var tahun = String(periode1.getFullYear());
            var bulan = String(periode1.getMonth() + 1);
            if (bulan.length === 1) {
                bulan = "0" + bulan;
            }
            var tanggal = String(periode1.getDate());
            if (tanggal.length === 1) {
                tanggal = "0" + tanggal;
            }

            var format_periode1 = tahun + "-" + bulan + "-" + tanggal;
            tahun = String(periode2.getFullYear());
            bulan = String(periode2.getMonth() + 1);
            if (bulan.length === 1) {
                bulan = "0" + bulan;
            }
            tanggal = String(periode2.getDate());
            if (tanggal.length === 1) {
                tanggal = "0" + tanggal;
            }

            var format_periode2 = tahun + "-" + bulan + "-" + tanggal;

            fetch(props.aplikasi + '/kasir/tampil_riwayat_retur.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            id_pengguna: localStorage.getItem("id_pengguna"),
                            periode1: format_periode1,
                            periode2: format_periode2
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        setDataPenjualan(data.data);
                    }
                    else {
                        setDataPenjualan([]);
                    }
                })
                .catch(error => {
                    setDataPenjualan([]);
                }
                );
        }, 10000);

        return () => {
            clearInterval(auto_refresh);
        };

    }, [props.aplikasi, periode1, periode2]);

    const tampil_penjualan_detail = (id_retur) => {
        fetch(props.aplikasi + '/kasir/tampil_retur_detail.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_retur: id_retur
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataPenjualanDetail(data.data);
                }
                else {
                    setDataPenjualanDetail([]);
                }
            })
            .catch(error => {
                setDataPenjualanDetail([]);
            }
            );
    }

    return (
        <div>
            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    <Grid item xs={12}>
                        <Card
                            sx={{ padding: "10px" }}
                        >
                            <Grid container spacing={1}>
                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3} >
                                        <Typography variant="body2">
                                            Tanggal Transaksi
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={9} sx={{ display: "flex" }}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                inputFormat="dd-MM-yyyy"
                                                value={periode1}
                                                onChange={(newValue) => {
                                                    setPeriode1(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>

                                        <Typography variant="body2" sx={{ marginLeft: "10px", marginRight: "10px" }}>
                                            s/d
                                        </Typography>

                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                inputFormat="dd-MM-yyyy"
                                                value={periode2}
                                                onChange={(newValue) => {
                                                    setPeriode2(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            {data_penjualan.map((data_penjualan, index) => {
                                return (
                                    <Grid
                                        key={index}
                                        item xs={6} sm={4} md={3} lg={2}
                                        onClick={() => {
                                            setIdRetur(data_penjualan.id_retur);
                                            setIdPenjualan(data_penjualan.id_penjualan);
                                            setNamaAnggota(data_penjualan.nama_anggota);                                            
                                            setKasir(data_penjualan.kasir);
                                            setWaktuTransaksi(data_penjualan.waktu_transaksi);
                                            setTotal(data_penjualan.subtotal);
                                            setPembulatan(data_penjualan.pembulatan);
                                            setGrandTotal(data_penjualan.grand_total);
                                            tampil_penjualan_detail(data_penjualan.id_retur);
                                            setTampilDialog(true);
                                        }}
                                    >
                                        <Card sx={{ backgroundColor: "#66615b", color: "white" }}>
                                            <List>
                                                <ListItem button>
                                                    <ListItemText
                                                        primary={<Typography noWrap variant="subtitle1">{data_penjualan.nama_anggota !== "" ? data_penjualan.nama_anggota : "Umum"}</Typography>}
                                                        secondary={
                                                            <div>
                                                                <Typography noWrap variant="body2" color="white">
                                                                    Rp. {data_penjualan.grand_total}
                                                                </Typography>
                                                                <Typography noWrap variant="body2" color="white">
                                                                    {data_penjualan.waktu_transaksi}
                                                                </Typography>
                                                            </div>
                                                        }
                                                    >
                                                    </ListItemText>
                                                </ListItem>
                                            </List>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <Dialog
                fullScreen
                open={tampil_dialog}
            >
                <Box>
                    <AppBar
                        sx={{
                            display: 'flex'
                        }}
                    >
                        <Toolbar
                            sx={{ backgroundColor: "#35b2b8" }}
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => setTampilDialog(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Detail Penjualan
                            </Typography>

                        </Toolbar>
                    </AppBar>

                    <Box sx={{ marginTop: "64px", padding: "10px" }}>
                        <Card sx={{ padding: "10px" }}>
                            <Typography variant="h6">
                                Data Penjualan
                            </Typography>
                            <Divider />
                            <br />

                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <Typography variant="body2">
                                                ID Retur
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                :
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                {id_retur}
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography variant="body2">
                                                No. Bukti
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                :
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                {id_penjualan}
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography variant="body2">
                                                Nama
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                :
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                {nama_anggota !== "" ? nama_anggota : "Umum"}
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography variant="body2">
                                                Kasir
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                :
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                {kasir}
                                            </Typography>
                                        </td>
                                    </tr>                                    
                                    <tr>
                                        <td>
                                            <Typography variant="body2">
                                                Waktu Transaksi
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                :
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                {waktu_transaksi}
                                            </Typography>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Card>

                        <br />

                        <Card sx={{ padding: "10px" }}>
                            <Typography variant="h6">
                                Daftar Produk
                            </Typography>
                            <Divider />
                            
                            <TableContainer component={Paper}>
                                <Table stickyHeader size="small" aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Nama</StyledTableCell>
                                            <StyledTableCell align="center">Metode</StyledTableCell>
                                            <StyledTableCell align="center">Catatan</StyledTableCell>
                                            <StyledTableCell align="center">Harga</StyledTableCell>
                                            <StyledTableCell align="center">Diskon</StyledTableCell>
                                            <StyledTableCell align="center">Jumlah</StyledTableCell>
                                            <StyledTableCell align="center">Satuan</StyledTableCell>
                                            <StyledTableCell align="center">Subtotal</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data_penjualan_detail.map((data_penjualan_detail) => {
                                            return (
                                                <StyledTableRow key={data_penjualan_detail.id_produk}>
                                                    <TableCell size="small"><Typography variant="body2" noWrap={true}>{data_penjualan_detail.nama}</Typography></TableCell>
                                                    <TableCell size="small"><Typography variant="body2" noWrap={true}>{data_penjualan_detail.metode_retur}</Typography></TableCell>
                                                    <TableCell size="small"><Typography variant="body2" noWrap={true}>{data_penjualan_detail.catatan}</Typography></TableCell>
                                                    <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{data_penjualan_detail.harga_jual2}</Typography></TableCell>
                                                    <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{data_penjualan_detail.diskon2}</Typography></TableCell>
                                                    <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{data_penjualan_detail.jumlah}</Typography></TableCell>
                                                    <TableCell size="small" align="center"><Typography variant="body2" noWrap={true}>{data_penjualan_detail.satuan}</Typography></TableCell>
                                                    <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{data_penjualan_detail.subtotal2}</Typography></TableCell>
                                                </StyledTableRow>
                                            );
                                        })}

                                        <TableRow>
                                            <TableCell align="right" colSpan={7}>
                                                <Typography variant="h6">
                                                    Total
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right"><Typography variant="h6" noWrap={true} sx={{ color: "green" }}>{total}</Typography></TableCell>
                                        </TableRow>                                        
                                        <TableRow>
                                            <TableCell align="right" colSpan={7}>
                                                <Typography variant="h6">
                                                    Pembulatan
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right"><Typography variant="h6" noWrap={true} sx={{ color: "green" }}>{pembulatan}</Typography></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" colSpan={7}>
                                                <Typography variant="h6">
                                                    Grand Total
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right"><Typography variant="h4" noWrap={true} sx={{ color: "red" }}>{grand_total}</Typography></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card>
                    </Box>
                </Box>
            </Dialog>                                   
        </div>
    );
}

export default forwardRef(Riwayat_Retur);