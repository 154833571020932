import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function Riwayat_Akuntansi(props, ref) {
    //state    
    const [periode1, setPeriode1] = useState(new Date());
    const [periode2, setPeriode2] = useState(new Date());
    const [data_transaksi, setDataTransaksi] = useState([]);

    //fungsi    
    useEffect(() => {
        var tahun = String(periode1.getFullYear());
        var bulan = String(periode1.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        var tanggal = String(periode1.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode1 = tahun + "-" + bulan + "-" + tanggal;

        tahun = String(periode2.getFullYear());
        bulan = String(periode2.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        tanggal = String(periode2.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode2 = tahun + "-" + bulan + "-" + tanggal;

        fetch(props.aplikasi + '/kasir/tampil_transaksi.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pengguna: localStorage.getItem("id_pengguna"),
                        periode1: format_periode1,
                        periode2: format_periode2
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataTransaksi(data.data);
                }
                else {
                    setDataTransaksi([]);
                }
            })
            .catch(error => {
                setDataTransaksi([]);
            }
            );

        const auto_refresh = setInterval(() => {
            var tahun = String(periode1.getFullYear());
            var bulan = String(periode1.getMonth() + 1);
            if (bulan.length === 1) {
                bulan = "0" + bulan;
            }
            var tanggal = String(periode1.getDate());
            if (tanggal.length === 1) {
                tanggal = "0" + tanggal;
            }

            var format_periode1 = tahun + "-" + bulan + "-" + tanggal;
            tahun = String(periode2.getFullYear());
            bulan = String(periode2.getMonth() + 1);
            if (bulan.length === 1) {
                bulan = "0" + bulan;
            }
            tanggal = String(periode2.getDate());
            if (tanggal.length === 1) {
                tanggal = "0" + tanggal;
            }

            var format_periode2 = tahun + "-" + bulan + "-" + tanggal;

            fetch(props.aplikasi + '/kasir/tampil_transaksi.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            id_pengguna: localStorage.getItem("id_pengguna"),
                            periode1: format_periode1,
                            periode2: format_periode2
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        setDataTransaksi(data.data);
                    }
                    else {
                        setDataTransaksi([]);
                    }
                })
                .catch(error => {
                    setDataTransaksi([]);
                }
                );
        }, 10000);

        return () => {
            clearInterval(auto_refresh);
        };

    }, [props.aplikasi, periode1, periode2]);

    return (
        <div>
            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    <Grid item xs={12}>
                        <Card
                            sx={{ padding: "10px" }}
                        >
                            <Grid container spacing={1}>
                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3} >
                                        <Typography variant="body2">
                                            Tanggal Transaksi
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={9} sx={{ display: "flex" }}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                inputFormat="dd-MM-yyyy"
                                                value={periode1}
                                                onChange={(newValue) => {
                                                    setPeriode1(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>

                                        <Typography variant="body2" sx={{ marginLeft: "10px", marginRight: "10px" }}>
                                            s/d
                                        </Typography>

                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                inputFormat="dd-MM-yyyy"
                                                value={periode2}
                                                onChange={(newValue) => {
                                                    setPeriode2(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            {data_transaksi.map((data_transaksi, index) => {
                                return (
                                    <Grid
                                        item xs={12} sm={6} md={4}
                                        key={index}
                                    >
                                        <Card sx={{ backgroundColor: "#66615b", color: "white" }}>
                                            <List>
                                                <ListItem button>
                                                    <ListItemText
                                                        primary={<Typography noWrap variant="subtitle1">{data_transaksi.keterangan}</Typography>}
                                                        secondary={
                                                            <div>
                                                                <Typography noWrap variant="body2" color="white">
                                                                    {data_transaksi.tgl_trans} (Rp. {data_transaksi.nominal})
                                                                </Typography>
                                                                <Typography noWrap variant="body2" color="white">
                                                                    D : {data_transaksi.gl_debit} - {data_transaksi.debit}
                                                                </Typography>
                                                                <Typography noWrap variant="body2" color="white">
                                                                    K : {data_transaksi.gl_kredit} - {data_transaksi.kredit}
                                                                </Typography>
                                                            </div>
                                                        }
                                                    >
                                                    </ListItemText>
                                                </ListItem>
                                            </List>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default forwardRef(Riwayat_Akuntansi);